import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { dateformatDDMMYEAR } from '../utils/Utils'
import { CartState } from '../Context/Context'
import LanguageSelector from '../screens/buyer/Language-selector'
import { useTranslation } from 'react-i18next'
import SignoutHandler from './SignoutHandler'
import { formatDateFit } from '../screens/buyer/BuyerRequestDetails'
const UserProfile = () => {
  const [showUserMenu, setShowUserMenu] = useState(false)
  const user = JSON.parse(localStorage.getItem('userData'))
  const [navigationURL, setnavigationURL] = useState('')
  const [openNotify, setCloseNOtify] = useState(false)
  const navigate = useNavigate()
  const {
    state: { notifyData, count },
    changeNotificationStaus,
    getNotificationCountForBuyer,
    getNotificationListForBuyer,
    getNotificationcountForSupplier,
    getNotificationListForSupplier
  } = CartState()

  // Notification List For Buyer

  useEffect(() => {
    const fetchNotifications = () => {
      if (user?.userGroupName !== 'OVERSEAS_ROLE') {
        if (user?.roleName === 'BUYER_ROLE') {
          getNotificationCountForBuyer(user?.email)
          getNotificationListForBuyer(user?.email)
        } else if (user?.roleName === 'SUPPLIER_ROLE') {
          getNotificationcountForSupplier(user?.supplierId)
          getNotificationListForSupplier(user?.supplierId)
        }
      }
    }
    // Initial fetch when the component mounts
    fetchNotifications()

    // Set up the interval to fetch notifications every 20 seconds (20000 milliseconds)
    const intervalId = setInterval(fetchNotifications, 20000)

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId)
  }, [])

  const changeNavigation = (type, hftID) => {
    const data =
      user?.roleName === 'BUYER_ROLE'
        ? type?.toLowerCase() === 'urgent'
          ? '/buyer/urgent_orders/request_details'
          : '/buyer/hft_buyer_change_requests/request_details'
        : type?.toLowerCase() === 'urgent'
          ? '/vendor/urgent_orders/request_details'
          : '/vendor/po_change_requests/request_details'
    return data + '/' + hftID
  }
  useEffect(() => {}, [notifyData, count])
  const notificationRef = useRef(null)
  const userLogout = useRef(null)
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        // Clicked outside the notification, close it
        setCloseNOtify(false)
      }
      if (userLogout.current && !userLogout.current.contains(event.target)) {
        // Clicked outside the notification, close it
        setShowUserMenu(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      // Cleanup: Remove the event listener when the component is unmounted
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])
  const { t } = useTranslation()
  const { Date, Name, Viewall, Signout, Notifications, Message } =
    t('userprofile')
  return (
    <div className='py-1 flex items-center justify-between gap-3 max-w-min'>
      <LanguageSelector />
      <div className='flex items-center relative'>
        {user?.userGroupName !== 'OVERSEAS_ROLE' && (
          <button onClick={() => setCloseNOtify(!openNotify)}>
            <svg
              className={`w-6 h-6  group ${
                openNotify ? 'text-blue-400' : 'text-gray-800'
              }`}
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 16 21'
            >
              <path
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M8 3.464V1.1m0 2.365a5.338 5.338 0 0 1 5.133 5.368v1.8c0 2.386 1.867 2.982 1.867 4.175C15 15.4 15 16 14.462 16H1.538C1 16 1 15.4 1 14.807c0-1.193 1.867-1.789 1.867-4.175v-1.8A5.338 5.338 0 0 1 8 3.464ZM4.54 16a3.48 3.48 0 0 0 6.92 0H4.54Z'
              />
            </svg>
            {count > 0 && (
              <span className='absolute -mt-6 ml-1 rounded-full bg-red-600 px-[0.45em] py-[0.2em] text-[0.6rem] leading-none text-white'>
                {count}
              </span>
            )}
          </button>
        )}
        {openNotify && notifyData?.length > 0 && (
          <div
            ref={notificationRef}
            className='absolute right-0 top-7 bg-[#e0ecfc] border border-gray-300 rounded-md shadow-lg overflow-hidden z-50 P-2 overflow-y-auto w-[250px] max-h-[500px] '
          >
            <h1 className='text-md  p-1 sticky top-0 bg-[#e0ecfc] w-[250px]'>
              {Notifications}
            </h1>
            {notifyData?.slice(0, 10)?.map((list) => {
              return (
                <Link
                  to={changeNavigation(list?.type, list?.iDOpenRequestID)}
                  onClick={() => {
                    changeNotificationStaus(list?.iDNOTIFICATION)
                    setCloseNOtify(false)
                  }}
                  className={` flex flex-col m-2 p-2   hover:bg-gray-100 text-xs ${
                    list?.status === 'Y' ? 'bg-purple-50' : 'bg-red-200'
                  } `}
                >
                  <p className='text-gray-600'>
                    <span className='font-bold uppercase'>
                    {list?.notificationType }
                    </span>{' '}
                    : {list?.dESCRIPTION}
                  </p>

                  <span>
                    {Date} : {formatDateFit(list?.createdDate)}{' '}
                  </span>
                  <span>
                    {Name} : {list?.cREATEDBY}{' '}
                  </span>
                  <p class='text-gray-600  font-bold  '>
                    {list?.iDOpenRequestID}
                  </p>
                </Link>
              )
            })}
            <button
              onClick={() => {
                navigate('/notification', {
                  state: { NotifyList: notifyData, navigateURL: navigationURL }
                })
                setCloseNOtify(false)
              }}
              className='sticky bottom-0 bg-[#e0ecfc] text-left p-1  text-xs text-black hover:underline w-[250px]'
            >
              {Viewall}{' '}
            </button>
          </div>
        )}
      </div>
      <div className='relative flex items-center justify-center'>
        <button
          className='flex gap-3 items-center justify-center text-xs font-medium'
          onClick={() => setShowUserMenu(!showUserMenu)}
        >
          <svg
            className='w-6 h-6 text-gray-800'
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='currentColor'
            viewBox='0 0 20 20'
          >
            <path d='M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z' />
          </svg>
          <p className='max-w-[100px] truncate'>
            {user?.roleName === 'SUPPLIER_ROLE'
              ? user?.supplierName
              : user?.fullName}
          </p>
          <svg
            className={`w-3 h-3 text-gray-400 ${showUserMenu ? 'rotate-180 duration-700' : 'duration-700'}`}
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 14 8'
          >
            <path
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1'
            />
          </svg>
        </button>
        <div
          ref={userLogout}
          className={`absolute top-4 right-1 mt-4 ${
            showUserMenu ? 'block' : 'hidden'
          }  bg-gray-800 text-white shadow-md w-96 rounded-lg text-xs flex flex-col justify-center z-10`}
        >
          <p className='p-2 px-3 cursor-pointer '>
            <SignoutHandler user={user} />
          </p>
        </div>
      </div>
    </div>
  )
}

export default UserProfile
