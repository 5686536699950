import React, { useEffect } from 'react';
import LandingPage from '../components/LandingPage';
import SignoutHandler from '../components/SignoutHandler';
import Cookies from 'js-cookie';
import { getCookie, getLocalStorageItem } from '../utils/Utils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LoginErrorPage = () => {
  const navigate = useNavigate();
  const{t}  = useTranslation()
  const{Sessiontimedout}  = t('Errorpage')
  const handleSignout = () => {
    const tokenId = getCookie('idcs_id_token');
    const roleCookie = getCookie('role');
    const roleStorage = getLocalStorageItem('role');
    const role = roleCookie || roleStorage;

    if (!tokenId || !role) {
      if (role) {
        // Redirect to intermediate page
        navigate('/refresh-login', { state: { role } })
      } else {
        navigate('/session-expired')
      }
      return
    }

    // Clear local storage and cookies, then redirect to logout URL
    clearLocalStorage()
    removeCookies()
    setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_ANOTHER_API_URL}/logout?role=${role}&token-id=${tokenId}`
    }, 1000)
  }

  const clearLocalStorage = () => {
    localStorage.clear()
  }

  const removeCookies = () => {
    Cookies.remove('idcs_user_assertion')
    Cookies.remove('idcs_id_token')
    Cookies.remove('role')
  }
  useEffect(()=>{
    handleSignout()
  },[])
  return (
    <LandingPage>
    <div className="flex flex-col items-center justify-center ">
          <div className="bg-white p-6 rounded-lg "> 
            <h1 className="text-2xl font-bold mb-4">{Sessiontimedout}</h1>
            {/* Removed old message from here  */}
         
          </div>
        </div>
   
    </LandingPage>
  );
};

export default LoginErrorPage;

