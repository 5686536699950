import React from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { getCookie, getLocalStorageItem } from '../utils/Utils';
import { useNavigate } from 'react-router-dom';

const SignoutHandler = ({ user }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { Signout,NAME,EMAIL,ROLE,CPIOFFICE } = t('userprofile');

  const handleSignout = () => {
    const tokenId = getCookie('idcs_id_token');
    const roleCookie = getCookie('role');
    const roleStorage = getLocalStorageItem('role');
    const role = roleCookie || roleStorage;

    if (!tokenId || !role) {
      if (role) {
        // Redirect to intermediate page
        navigate('/refresh-login', { state: { role } })
      } else {
        navigate('/session-expired')
      }
      return
    }

    // Clear local storage and cookies, then redirect to logout URL
    clearLocalStorage()
    removeCookies()
    setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_ANOTHER_API_URL}/logout?role=${role}&token-id=${tokenId}`
    }, 1000)
  }

  const clearLocalStorage = () => {
    localStorage.clear()
  }

  const removeCookies = () => {
    Cookies.remove('idcs_user_assertion')
    Cookies.remove('idcs_id_token')
    Cookies.remove('role')
  }

  return (
    <div className=' '>
      {
        user && 
        <div className='space-y-3 p-3 text-md duration-1000'>
             <div className='flex gap-2 '>
            <span className='font-bold'>{NAME} - </span>
            <p >
            {user?.roleName === 'SUPPLIER_ROLE'
              ? user?.supplierName
              : user?.fullName}
          </p>
           </div>
           <div className='flex gap-2 '>
            <span className='font-bold'>{EMAIL} - </span>
            <p className=' uppercase '>
            {user?.email}
          </p>
           </div>
          <div className='gap-2 flex'>
            <span className='font-bold'>{ROLE} - </span>
            <p >
            {user?.userGroupName }
          </p>
          </div>
          {user?.userGroupName==="OVERSEAS_ROLE"  && <div className='gap-2 flex'>
            <span className='font-bold'>{CPIOFFICE} - </span>
            <p >
            { user?.roleType}
          </p>
          </div>}

          </div>
      }
              <button className="text-blue-500 underline  text-sm text-end cursor-pointer"
        onClick={handleSignout}>{Signout}
      </button>
    </div>
  )
}

export default SignoutHandler
