import React, { useEffect, useState } from 'react'
import UserProfile from '../../components/UserProfile'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { getWeek, getWeekDifferenceStatus } from './NewPoList'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  OpenSpecificDate,
  ValidateAPI,
  changeDateFormat,
  checkstatus,
  getPossibleShipWeek,
  getStartDate,
  handleDownload,
  highLightDateField,
  updateActionHistory
} from '../../utils/Utils'
import Comments from '../../components/Comments'
import Loading from '../../components/Loading'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import Stepper from '../../components/Stepper'
import PopupModal from '../../components/PopupModal'
import PopupBanner from '../../components/PopupBanner'

export const formatDateFit = (timestampString) => {
  // 11/16/2024 00:32:58 AM
  if (!timestampString) return ''
  // const year = timestampString?.slice(0, 4)
  // const month = timestampString?.slice(5, 7)
  // const day = timestampString?.slice(8, 10)
  // const hour = timestampString?.slice(11, 13)
  // const minute = timestampString?.slice(14, 16)
  // const second = timestampString?.slice(17, 19)

  // const formattedDate = `${month}-${day}-${year} ${hour}:${minute}:${second}`

  // getting date format like this and formatting this 08/26/2024 10:34:50 AM from API
  const formattedDate = timestampString?.slice(0, 19)
  return formattedDate
}

const BuyerRequestDetails = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const user = JSON.parse(localStorage.getItem('userData'))
  const { id } = useParams()
  const [requestId, setRequestId] = useState('')
  const [poNumber, setPoNumber] = useState('')
  const [sku, setSKU] = useState('')
  const [showRequestUpdateModal, setShowRequestUpdateModal] = useState(false)
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false)
  const steps = ['Awaiting Response', 'Vendor Responded', 'Closed']
  const stepsCancel = [
    'Awaiting Response',
    'Cancelled',
    'Vendor Responded',
    'Closed'
  ]
  const [status, setStatus] = useState('')
  let currentStepIndex = steps?.indexOf(status)
  const currentStepIndexCancel = stepsCancel?.indexOf(status)
  const [searchWord, setSearchWord] = useState()
  const [limiter, setLimiter] = useState(10)
  const location = useLocation()
  const navigate = useNavigate()
  const [requestData, setRequestData] = useState({})
  const [commentsData, setCommentsData] = useState([])
  const [commentInput, setCommentInput] = useState('')
  const [showEdit, setShowEdit] = useState(false)
  const [requestSW, setRequestSW] = useState(requestData?.requested_ship_week)
  const [requestDC, setRequestDC] = useState(requestData?.requested_dc)
  const [date, setDate] = useState(new Date())
  const [loading, setLoading] = useState(true)
  const [dcList, setDCList] = useState(
    JSON.parse(sessionStorage?.getItem('dcList'))
  )
  const [swdisable, setSWdisable] = useState(false)
  const [dcdisable, setDCdisable] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const { t } = useTranslation()
  const [messageForBanner, setMessageForBanner] = useState("")
  const {CURRSW,CARGOREADYSW,BOOKINGSW,UnitCost,ExtendedCost,PrevSW,
    HFTBuyerPOChangeRequestDetails,
    Home,
    HFTBuyerChangeRequests,
    ChangeRequest,
    PO,
    DC,
    Supplier,
    supplierName,
    DownloadPurchaseOrder,
    POHeaderDesc,
    ShippingOffice,
    InventoryAnalyst,
    CreateDate,
    VendorManager,
    ReasonCode,
    VendorResponse,
    RequestType,
    LastUpdateDate,
    POLineChangeRequestDetails,
    Edit,
    Rev,
    SKU,
    Description,
    ORGSW,
    ChangeSW,
    QTY,
    ORGDC,
    ChangeDC,
    Action,
    Week,
    Save,
    Cancel,
    LastUpdated,
    VendorAcceptedSW,
    CancelOrder,
    OracleSyncStatus
  } = t('HFTBuyerPOChangeRequestDetailsPage')
  console.log(t('HFTBuyerPOChangeRequestDetailsPage'))
  console.log(t('sidebar'))
  const fetchRequestData = () => {
    setLoading(true)
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-cr-by-id?idOpenRequest=${id}`, ValidateAPI()
      )
      .then((response) => {
        // console.log("response from request: ", response?.data);
        setRequestData(response?.data)
        setLoading(false)
        setRequestId(response?.data?.id_open_request)
        setPoNumber(response?.data?.po_number)
        setSKU(response?.data?.sku)
      })
      .catch((error) => {
        console.log(`error while fetching request data: `, error)
        setLoading(false)
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
  }

  const closeRequestUpdateModal = () => {
    setShowRequestUpdateModal(false)
  }

  const openRequestUpdateModal = () => {
    setShowRequestUpdateModal(true)
  }

  const handleRequestUpdate = () => {
    const swStatus = requestSW
      ? getWeekDifferenceStatus(requestData?.original_ship_week, requestSW)
      : ''
    const dcStatus =
      requestData?.original_dc !== requestDC
        ? requestDC?.length > 0
          ? 'Divert'
          : ''
        : ''
    const payload = [
      {
        hft_cr_id: requestData?.id_open_request,
        // request_type: `${swStatus + " " + dcStatus}`,
        request_type: swStatus ? swStatus : dcStatus,
        original_dc: requestData?.original_dc,
        requested_dc: requestDC ? requestDC : requestData?.requested_dc,
        reason_code_dc: '',
        original_ship_week: requestData?.original_ship_week,
        requested_ship_week: requestSW
          ? requestSW
          : requestData?.requested_ship_week,
        reason_code_ship_week: '',
        status: 'OPEN',
        followups: 1,
        current_ship_week: requestData?.current_ship_week
      }
    ]
    console.log(payload)
    if (swStatus || dcStatus) {
      console.log(swStatus, 'sw')
      console.log('dc', dcStatus)
      axios
        .put(
          `${process.env.REACT_APP_API_DOMAIN}:8088/api/edit-request`,
          payload, ValidateAPI()
        )
        .then((response) => {
          console.log('response from update: ', response?.data)
          setShowEdit(false)
          setRequestData(response?.data?.[0])
          // alert(i18next.t('PopUpMessage.ChangeRequestupdated'))
          setMessageForBanner(i18next.t("VendorPopUp.ChangedRequestUpdatedSuccessfully"))
          setRefresh(true)
        })
        .catch((error) => {
          if (error.response.status === 401 || error.response.status === 403) {
            navigate('/login-error')
          }
          else if (error.response.status === 500) {
            // alert("Data Already Updated ")
            setMessageForBanner(i18next.t("VendorPopUpDataAlreadyUpdated."))
            setRefresh(true)
            setShowEdit(false)
            fetchRequestData()
          }
          else {
            alert(
              i18next.t(
                'PopUpMessage.ErrorwhileupdatingChangeRequestPleasetryagain'
              )
            )
          }
        }
        )
      closeRequestUpdateModal()
    } else {
      alert(i18next.t('PopUpMessage.Thereisnochanges'))
    }
  }

  const handleChangeDate = (date) => {
    if (!date) {
      // If date is cleared, set to today's date
      date = new Date();
    }
    setDate(date);

    if (date) {
      setRequestSW(getWeek(date));
      setDCdisable(true);
    } else {
      setDCdisable(false);
    }
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    // If the input is empty, set date to today
    if (!inputValue) {
      const today = new Date();
      setDate(today);
      handleChangeDate(today);
      return;
    }


    const parsedDate = new Date(inputValue);

    // Check if the input is valid
    if (!isNaN(parsedDate.getTime())) {
      setDate(parsedDate);
      handleChangeDate(parsedDate);
    } else {
      console.log("Invalid date format");
      // Optionally, provide feedback to the user
      alert("Please enter a valid date in the format MM/DD/YYYY or YYYY-MM-DD");
    }
  };

  const handlecancelChange = () => {
    setShowEdit(false)
    setSWdisable(false)
    setDCdisable(false)
    setRequestDC('')
    setRequestSW('')
  }
  useEffect(() => {
    fetchRequestData()
    setRequestSW(requestData?.requested_ship_week)
    setRequestDC(requestData?.requested_dc)
  }, [id])

  const closeCancelOrderModal = () => {
    setShowCancelOrderModal(false)
  }

  const openCancelOrderModal = () => {
    setShowCancelOrderModal(true)
  }

  const handleCancelOrder = () => {
    const payload = {
      hftCrId: requestData?.id_open_request,
      createdBy: requestData?.created_by,
      vendorNumber: requestData?.vendor_number,
      po_number: requestData?.po_number,
      request_type: requestData?.request_type,
      vendor_name: requestData?.vendor_name,
      sku: requestData?.sku,
      inventory_analayst: requestData?.inventory_analyst
    }
    // console.log(payload);
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/cancel-change-request`,
        payload, ValidateAPI()
      )
      .then((response) => {
        console.log('response from update: ', response?.data)
        updateActionHistory('Cancelled', [requestData])
        alert(i18next.t('PopUpMessage.ChangeRequestCancelled'))
        fetchRequestData()
      })
      .catch((error) => {
        alert(
          i18next.t(
            'PopUpMessage.ErrorwhileupdatingChangeRequestPleasetryagain'
          )
        )
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
    console.log(payload)
    closeCancelOrderModal()
  }

  useEffect(() => {
    if (requestData?.status?.toLowerCase() === 'open') {
      if (requestData?.supplier_response === 'Awaiting Response') {
        setStatus('Awaiting Response')
      } else if (
        requestData?.supplier_response === 'Accept' ||
        requestData?.supplier_response === 'ACCEPTED'
      ) {
        setStatus('Vendor Responded')
      } else if (
        requestData?.supplier_response === 'Reject' ||
        requestData?.supplier_response === 'REJECTED'
      ) {
        setStatus('Vendor Responded')
      } else if (requestData?.supplier_response === 'Vendor Initiated') {
        setStatus('Vendor Responded')
      }
    } else if (requestData?.status?.toLowerCase() === 'closed') {
      setStatus('Closed')
    } else {
      setStatus('Cancelled')
      currentStepIndex = stepsCancel?.indexOf(status)
    }
  }, [requestData?.supplier_response, requestData?.status])

  const closePopUpModal = () => {

    setRefresh(false)
  }
  useEffect(() => {
    // GetDCList()
  }, [])
  useEffect(() => {
    const handleClickOutside = () => {
      setRefresh(false);
    };
    if (refresh) {
      window.addEventListener('click', handleClickOutside);
    }
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [refresh]);
  return (
    <div className='w-full flex flex-col gap-6 justify-start items-start p-4'>
      {!loading ? (
        <>
          <div className='w-full justify-between flex items-center'>
            <div className=''>
              <p className='text-xl font-medium'>
                {HFTBuyerPOChangeRequestDetails}
              </p>
            </div>
          </div>
          <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
            <p className='flex gap-1 items-center justify-center'>
              <span
                className='hover:underline cursor-pointer'
                onClick={() => navigate('/buyer')}
              >
                {Home}
              </span>
              <span>{'>'}</span>
            </p>
            <p className='flex gap-1 items-center justify-center'>
              <span
                className='hover:underline cursor-pointer'
                onClick={() => navigate('/buyer/hft_buyer_change_requests')}
              >
                {HFTBuyerChangeRequests}
              </span>
              <span>{'>'}</span>
            </p>
            <p className='flex gap-1 items-center justify-center'>
              <span>
                {ChangeRequest} {id}
              </span>
            </p>
          </div>
          {/*Stepper to show current status */}
          {/* <div className="w-full flex justify-center items-start relative">
            <div className="w-[70%] border border-gray-200 absolute top-3" />
            <div className="w-[80%] flex justify-between items-center">
              {(requestData?.status?.toLowerCase() === "cancelled"
                ? stepsCancel
                : steps
              )?.map((step, index) => (
                <div
                  className="flex flex-col z-40 justify-start items-center gap-2 h-[100px]"
                  key={index + step}
                >
                  <div
                    className={`rounded-full border-2 p-1 ${
                      (requestData?.status?.toLowerCase() === "cancelled"
                        ? currentStepIndexCancel
                        : currentStepIndex) >= index
                        ? "border-blue-600"
                        : "border-gray-300"
                    } w-6 h-6 flex justify-center items-center `}
                  >
                    <div
                      className={`p-1.5 rounded-full ${
                        (requestData?.status?.toLowerCase() === "cancelled"
                          ? currentStepIndexCancel
                          : currentStepIndex) >= index
                          ? "bg-blue-600"
                          : "bg-gray-300"
                      }`}
                    ></div>
                  </div>
                  <p
                    className={`${
                      (requestData?.status?.toLowerCase() === "cancelled"
                        ? currentStepIndexCancel
                        : currentStepIndex) >= index
                        ? "text-blue-600"
                        : "text-gray-400"
                    } text-center text-xs font-medium w-[160px]`}
                  >
                    {step}
                    
                  </p>
                  <p
                  className={`${
                    (requestData?.status?.toLowerCase() === "cancelled"
                      ? currentStepIndexCancel
                      : currentStepIndex) >= index
                      ? "text-blue-600"
                      : "text-gray-400"
                  } text-center text-xs font-medium w-[160px]`}
                  >
                    {step==="Awaiting Response" &&formatDateFit(requestData?.created_at)}
                   { requestData?.status?.toLowerCase() === "cancelled" ?
                   index==currentStepIndexCancel &&formatDateFit(requestData?.updated_at) :
                   index==currentStepIndex &&formatDateFit(requestData?.updated_at)
                   }
                    </p>
                </div>
              ))}
            </div>
          </div> */}
          <Stepper
            status={status}
            crId={requestId}
            iscancelled={requestData?.status?.toLowerCase() === 'cancelled'}
          />
          {/* Cancel Order Button  */}
          {user?.userGroupName !== "OVERSEAS_ROLE" && requestData?.status === 'OPEN' &&
            requestData?.supplier_response === 'Awaiting Response' && (
              <div className='flex '>
                {/* <button
                  className='bg-[#4472C4] text-white h-[37px] px-3 rounded-md text-sm'
                  onClick={openCancelOrderModal}
                >
                  {CancelOrder}
                </button> */}
                <button
                  className={`bg-red-500 text-white h-[37px] px-3 rounded-md text-sm `}
                  onClick={openCancelOrderModal}
                  // disabled={requestData?.request_type === 'Confirm'}
                  style={{ cursor: 'pointer' }}
                >
                  {CancelOrder}
                </button>

                <PopupModal
                  isOpen={showCancelOrderModal}
                  message={i18next.t('PopUpMessage.Doyouwanttocancelorder')}
                  onCancel={closeCancelOrderModal}
                  cancelText={i18next.t('AcceptModal.Cancel')}
                  onConfirm={handleCancelOrder}
                  confirmText={i18next.t('AcceptModal.Confirm')}
                />
              </div>
            )}
          <div className='w-full border border-[#E6EDFF] rounded-md p-3'>
            <table className='w-full'>
              <tbody>
                <tr className='border-b border-[#E6EDFF] '>
                  <td className='text-xs p-2 py-4'>
                    <div className='flex flex-col gap-1'>
                      <p className='font-medium'>{PO}</p>
                      <p>{requestData?.po_number}</p>
                    </div>
                  </td>
                  <td className='text-xs p-2 py-4'>
                    <div className='flex flex-col gap-1'>
                      <p className='font-medium'>{Supplier}</p>
                      <p>{requestData?.vendor_number}</p>
                    </div>
                  </td>
                  <td className='text-xs p-2 py-4'>
                    <div className='flex flex-col gap-1'>
                      <p className='font-medium'>{supplierName}</p>
                      <p>{requestData?.vendor_name}</p>
                    </div>
                  </td>
                  {user?.userGroupName !== "OVERSEAS_ROLE" &&
                    <td className='text-xs p-2 py-4'>
                      <div className='flex flex-col gap-1'>
                        <p className='font-medium'>{DownloadPurchaseOrder}</p>
                        <button
                          className='text-xs text-center flex justify-center px-4'
                          onClick={() => handleDownload(requestData?.po_number)}
                        >
                          <svg
                            className='w-4 h-4  cursor-pointer'
                            aria-hidden='true'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 16 18'
                          >
                            <path
                              stroke='currentColor'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='1.5'
                              d='M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3'
                            />
                          </svg>
                        </button>
                      </div>
                    </td>}
                  <td className='text-xs p-2 py-4'>
                    <div className='flex flex-col gap-1'>
                      <p className='font-medium'>{OracleSyncStatus}</p>
                      <p>{
                        requestData?.request_type === "ReConfirm" &&
                          requestData?.original_ship_week === requestData?.current_ship_week &&
                          requestData?.supplier_response === "ACCEPTED"
                          ?
                          "Not Applicable"
                          : checkstatus(requestData?.process_flag)}
                      </p>
                    </div>
                  </td>
                </tr>
                <tr className='border-b border-[#E6EDFF]'>
                  <td className='text-xs p-2 py-4'>
                    <div className='flex flex-col gap-1'>
                      <p className='font-medium'>{POHeaderDesc}</p>
                      <p></p>
                    </div>
                  </td>
                  <td className='text-xs p-2 py-4'>
                    <div className='flex flex-col gap-1'>
                      <p className='font-medium'>{ShippingOffice}</p>
                      <p></p>
                    </div>
                  </td>
                  <td className='text-xs p-2 py-4'>
                    <div className='flex flex-col gap-1'>
                      <p className='font-medium'>{InventoryAnalyst}</p>
                      <p>{requestData?.inventory_analyst}</p>
                    </div>
                  </td>
                  <td className='text-xs p-2 py-4'>
                    <div className='flex flex-col gap-1'>
                      <p className='font-medium'>{CreateDate}</p>
                      <p>{formatDateFit(requestData?.CREATION_DATE)}</p>
                    </div>
                  </td>
                  <td className='text-xs p-2 py-4'>
                    <div className='flex flex-col gap-1'>
                      <p className='font-medium'>{VendorManager}</p>
                      <p>{requestData?.vendor_manager}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className='text-xs p-2 py-4'>
                    <div className='flex flex-col gap-1'>
                      <p className='font-medium'>{ReasonCode}</p>
                      <p>
                        {`${requestData?.reason_code_dc ?? ''}  ${requestData?.reason_code_ship_week ?? ''
                          }`}
                      </p>
                    </div>
                  </td>
                  <td className='text-xs p-2 py-4'>
                    <div className='flex flex-col gap-1'>
                      <p className='font-medium'>{VendorResponse}</p>
                      <p>{requestData?.supplier_response}</p>
                    </div>
                  </td>
                  <td className='text-xs p-2 py-4'>
                    <div className='flex flex-col gap-1'>
                      <p className='font-medium'>{RequestType}</p>
                      <p className='capitalize'>{requestData?.request_type}</p>
                    </div>
                  </td>
                  <td className='text-xs p-2 py-4'>
                    <div className='flex flex-col gap-1'>
                      <p className='font-medium'>{LastUpdateDate}</p>
                      <p>{formatDateFit(requestData?.updated_at)}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/*PO Line Change Requests */}
          <div className='w-full flex justify-start items-start mt-1 flex-col gap-4 overflow-x-auto'>
            <div className='w-full flex justify-between items-center'>
              <p className='font-semibold text-xl'>
                {POLineChangeRequestDetails}
              </p>
              {user?.userGroupName !== "OVERSEAS_ROLE" && requestData?.supplier_response === 'Awaiting Response' &&
                requestData?.status === 'OPEN' && (
                  <p
                    className='hover:underline cursor-pointer text-sm font-medium'
                    onClick={() => setShowEdit(!showEdit)}
                  >
                    {Edit}
                  </p>
                )}
            </div>

            {showEdit ? (
              <div className='w-full p-3 border border-[#E6EDFF] rounded-md'>
                <table className='w-full'>
                  <thead>
                    <tr className='border-b border-[#E6EDFF]'>
                      <th className='p-2 py-4 font-medium text-xs'>{Rev}</th>
                      <th className='p-2 py-4 font-medium text-xs'>{SKU}</th>
                      <th className='p-2 py-4 font-medium text-xs'>{Description}</th>
                      {requestData?.request_type !== 'Confirm' &&
                        requestData?.request_type !== 'Divert' &&
                        requestData?.request_type !== 'Booking'&&
                          (
                            <th className='p-2 py-4 font-medium text-xs'>Prev SW</th>
                          )}
                      <th className='p-2 py-4 font-medium text-xs'>
                        {CURRSW}
                      </th>

                      <th className='p-2 py-4 font-medium text-xs'>{ORGSW}</th>
                      {
                        requestData?.request_type !== 'Divert' && (
                          <th className='p-2 py-4 font-medium text-xs'>
                            {ChangeSW}
                          </th>
                        )}
                      <th className='p-2 py-4 font-medium text-xs'>{QTY}</th>
                      <th className='p-2 py-4 font-medium text-xs'>{ORGDC}</th>
                      {requestData?.original_dc !== requestData?.requested_dc &&
                        requestData?.request_type === 'Divert' &&
                        requestData?.request_type !== 'Confirm'
                        && (
                          <th className='p-2 py-4 font-medium text-xs'>
                            {ChangeDC}
                          </th>
                        )}
                      {
                        requestData?.request_type === 'Confirm' &&
                        <>
                          <th className='p-2 py-4 font-medium  text-xs'>{UnitCost}</th>
                          <th className='p-2 py-4 font-medium  text-xs'>{ExtendedCost}</th>
                        </>
                      }
                      <th className='p-2 py-4 font-medium text-xs'>{Action}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='text-center'>
                      <td className='p-2 py-4 text-xs'>
                        {requestData?.revision_number}
                      </td>
                      <td className='p-2 py-4 text-xs'>{requestData?.sku}</td>
                      <td className='p-2 py-4 text-xs'>
                        {requestData?.description}
                      </td>
                      {requestData?.request_type !== 'Confirm' &&
                        requestData?.request_type !== 'Divert' &&
                        requestData?.request_type !== 'Booking' &&
                        <th className='p-2 py-4 font-medium text-xs'>
                          {requestData?.PREVS_CURRENTSHIPWEEK}
                        </th>}
                      <th className='p-2 py-4 font-medium text-xs'>
                        {requestData?.current_ship_week}
                      </th>
                      <td className='p-2 py-4 text-xs'>
                        {requestData?.original_ship_week}
                      </td>
                      {
                        requestData?.request_type !== 'Divert' && (
                          <td className='p-2 py-4 text-xs'>
                            <div className='flex flex-col gap-1 justify-center items-center'>
                              <p>
                                {Week}{' '}
                                <span className='font-medium'>{requestSW ? requestSW : requestData?.requested_ship_week}</span>
                              </p>
                              <ReactDatePicker
                                showWeekNumbers
                                filterDate={(date) => date.getDay() === 1}
                                selected={date || new Date()}
                                // selected={date}
                                // onChange={(date) => {
                                //   handleChangeDate(date)
                                //   setDCdisable(true)
                                // }}
                                onChange={handleChangeDate}
                                className='p-1 border border-gray-300 rounded-md bg-yellow-300'
                                calendarStartDay={1}
                                // minDate={new Date()}
                                minDate={
                                  requestData?.request_type === "Defer" ?
                                    getStartDate(
                                      getPossibleShipWeek(
                                        requestData?.current_ship_week
                                      )) : new Date()
                                }
                                maxDate={
                                  requestData?.request_type !== "Defer" ?
                                    getStartDate(
                                      getPossibleShipWeek(
                                        requestData?.current_ship_week
                                      ), -1) : ''}
                                excludeDates={[
                                  new Date(changeDateFormat(requestData?.requested_ship_week)),
                                  new Date(changeDateFormat(requestData?.current_ship_week)), new Date()
                                ]}
                                highlightDates={highLightDateField(requestData?.current_ship_week)}
                                showYearDropdown
                                disabled={swdisable}
                                openToDate={
                                  getStartDate(getPossibleShipWeek(requestData?.current_ship_week))
                                }
                                onInputChange={handleInputChange}
                              />

                            </div>
                          </td>
                        )}
                      <td className='p-2 py-4 text-xs'>
                        {requestData?.original_qty}
                      </td>
                      <td className='p-2 py-4 text-xs'>
                        {requestData?.original_dc}
                      </td>
                      {requestData?.original_dc !== requestData?.requested_dc &&
                        requestData?.request_type === 'Divert' &&
                        requestData?.request_type !== 'Confirm' &&
                        (
                          <td className='p-2 py-4 text-xs flex flex-col items-center'>
                            <div>
                              {/* <input
                                type="text"
                                name="request_dc"
                                id="request_dc"
                                value={requestDC}
                                onChange={(e) =>
                                  setRequestDC(Number(e?.target?.value))
                                }
                                className="p-1 border border-gray-300 rounded-md"
                              /> */}
                              {requestData?.requested_dc}
                              <select
                                id='dcValue'
                                name='dcValue'
                                value={requestDC}
                                disabled={dcdisable}
                                class='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  bg-yellow-300 focus:ring-blue-500 focus:border-blue-500 block  p-1 '
                                onChange={(e) => {
                                  setRequestDC(e?.target?.value)
                                  setSWdisable(true)
                                }}
                              >
                                <option value='' selected disabled>
                                  {DC}
                                </option>
                                {dcList?.map((data) => {
                                  return (
                                    <option
                                      value={data?.DC_NAME}
                                      className="bg-white text-black"
                                      disabled={
                                        data?.DC_NAME === requestData?.requested_dc
                                        ||
                                        data?.DC_NAME === requestData?.original_dc}>
                                      {data?.DC_NAME}

                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                          </td>
                        )}
                      {
                        requestData?.request_type === 'Confirm' &&
                        <>
                          <td className='p-2 py-4 text-xs'>{requestData?.unitPrice}</td>
                          <td className='p-2 py-4 text-xs'>{requestData?.extendedPrice}</td>
                        </>
                      }
                      <td className='p-2 py-4 text-xs'>
                        <div className='flex gap-3 items-center justify-center w-full'>
                          <button
                            className='hover:underline'
                            onClick={openRequestUpdateModal}
                          >
                            {Save}
                          </button>
                          <PopupModal
                            isOpen={showRequestUpdateModal}
                            message={i18next.t('PopUpMessage.Doyouwanttoupdatechangerequest')}
                            onCancel={closeRequestUpdateModal}
                            cancelText={i18next.t('AcceptModal.Cancel')}
                            onConfirm={handleRequestUpdate}
                            confirmText={i18next.t('AcceptModal.Confirm')}
                          />
                          <button
                            className='hover:underline'
                            onClick={handlecancelChange}
                          >
                            {Cancel}
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div className='w-full p-3 border border-[#E6EDFF] rounded-md'>
                <table className='w-full text-center'>
                  <thead className='align-top'>
                    <tr className='border-b border-[#E6EDFF]'>
                      <th className='p-2 py-4 font-medium text-xs'>{Rev}</th>
                      <th className='p-2 py-4 font-medium text-xs'>{SKU}</th>
                      <th className='p-2 py-4 font-medium text-xs'>
                        {Description}
                      </th>
                      {
                        requestData?.request_type !== 'Confirm' &&
                        requestData?.request_type !== 'Divert' &&
                        requestData?.request_type !== 'Booking' && (
                          <th className='p-2 py-4 font-medium text-xs'>Prev SW</th>
                        )}
                      <th className='p-2 py-4 font-medium text-xs'>CURR SW</th>
                      <th className='p-2 py-4 font-medium text-xs'>{ORGSW}</th>
                      {requestData?.requested_ship_week &&
                        requestData?.request_type !== 'Divert' && (
                          <th className='p-2 py-4 font-medium text-xs'>
                            {ChangeSW}
                          </th>
                        )}

                      <th className='p-2 py-4 font-medium text-xs'>
                        {VendorAcceptedSW}
                      </th>
                      {requestData?.request_type === 'Booking' &&
                        <td className='p-2 py-4 font-medium text-xs'>
                          {CARGOREADYSW}
                        </td>
                      }
                      {requestData?.request_type === 'Booking' &&
                        <td className='p-2 py-4 font-medium text-xs'>
                          {BOOKINGSW}
                        </td>
                      }
                      {
                        user?.userGroupName !== "OVERSEAS_ROLE" &&
                        <th className='p-2 py-4 font-medium text-xs'>{QTY}</th>}
                      <th className='p-2 py-4 font-medium text-xs'>{ORGDC}</th>
                      {requestData?.original_dc !== requestData?.requested_dc &&
                        requestData?.request_type !== 'Confirm' &&
                        (
                          <th className='p-2 py-4 font-medium text-xs'>{ChangeDC}</th>
                        )}
                      {
                        requestData?.request_type === 'Confirm' &&
                        <>
                          <th className='p-2 py-4 font-medium  text-xs'>{UnitCost}</th>
                          <th className='p-2 py-4 font-medium  text-xs'>{ExtendedCost}</th>
                        </>
                      }
                      <th className='p-2 py-4 font-medium text-xs min-w-max'>
                        {LastUpdated}
                      </th>
                    </tr>
                  </thead>
                  <tbody className='align-top'>
                    <tr className='text-center'>
                      <td className='p-2 py-4 text-xs'>
                        {requestData?.revision_number}
                      </td>
                      <td className='p-2 py-4 text-xs'>{requestData?.sku}</td>
                      <td className='p-2 py-4 text-xs'>
                        {requestData?.description}
                      </td>
                      {
                        requestData?.request_type !== 'Confirm' &&
                        requestData?.request_type !== 'Divert' &&
                        requestData?.request_type !== 'Booking' &&
                        <td className='p-2 py-4 text-xs'>
                          {requestData?.PREVS_CURRENTSHIPWEEK}
                        </td>
                      }
                      <td className='p-2 py-4 text-xs'>
                        {requestData?.current_ship_week}
                      </td>
                      <td className='p-2 py-4 text-xs'>
                        {requestData?.original_ship_week}
                      </td>
                      {requestData?.requested_ship_week &&
                        requestData?.request_type !== 'Divert' && (
                          <td className='p-2 py-4 text-xs gap-1 relative'>
                            <p>{requestData?.requested_ship_week}</p>
                            {requestData?.requested_ship_week && requestData?.request_type !== 'Booking' && (
                              <span
                                class="absolute top-2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-2 h-2 bg-green-500 rounded-full"
                              ></span>
                            )}
                          </td>
                        )}

                      <td className='p-2 py-4 text-xs'>
                        {requestData?.request_type === 'Delay'
                          ? requestData?.current_ship_week
                          : requestData?.vendor_selected_sw}
                      </td>
                      {requestData?.request_type === 'Booking' &&
                        <td className='p-2 py-4 text-xs  relative'>
                          {requestData?.cargoReadySW && <span
                            class="absolute top-2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-2 h-2 bg-green-500 rounded-full"
                          ></span>}
                          {requestData?.cargoReadySW}
                        </td>
                      }
                      {requestData?.request_type === 'Booking' &&
                        <td className='p-2 py-4 text-xs  relative'>
                          {requestData?.bookingSW && <span
                            class="absolute top-2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-2 h-2 bg-green-500 rounded-full"
                          ></span>}
                          {requestData?.bookingSW}
                        </td>
                      }
                      <td className='p-2 py-4 text-xs'>
                        {requestData?.original_qty}
                      </td>
                      <td className='p-2 py-4 text-xs'>
                        {requestData?.original_dc}
                      </td>
                      {requestData?.original_dc !== requestData?.requested_dc &&
                        requestData?.request_type !== 'Confirm' &&
                        (
                          <td className='p-2 py-4 text-xs flex justify-center gap-1 relative'>
                            {requestData?.requested_dc}
                            {requestData?.requested_dc && (
                              <div className='bg-green-500 p-1 rounded-full absolute  top-1'></div>
                            )}
                          </td>
                        )}
                      {
                        requestData?.request_type === 'Confirm' &&
                        <>
                          <td className='p-2 py-4 text-xs'>{requestData?.unitPrice}</td>
                          <td className='p-2 py-4 text-xs'>{requestData?.extendedPrice}</td>
                        </>
                      }
                      <td className='p-2 py-4 text-xs min-w-max'>
                        {formatDateFit(requestData?.updated_at)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className='w-full'>
            <Comments crId={requestId}
              poNumber={poNumber}
              sku={sku}
              status={requestData?.status === "CLOSED" || requestData?.status === "CANCELLED"}

              refresh={refresh}
            />
          </div>
          <PopupBanner
            isOpen={refresh}
            message={messageForBanner}
            onCancel={closePopUpModal}
          // text={poNumber}
          // text1={[id]}
          />
        </>
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default BuyerRequestDetails
