import React, { useEffect, useRef, useState, useTransition } from 'react'
import DatePicker from 'react-datepicker'
import {
  getDateofMonday,
  getPossibleShipWeek,
  getStartDate,
  highLightDateField,
  makeSWformat,
  OpenSpecificDate,
  validateSWforBooking
} from '../../utils/Utils'
import PopupModal from '../PopupModal'
import { weeksInBetween } from '../../screens/vendor/ConfirmPo'
import { getWeek } from '../../screens/buyer/NewPoList'
import { EditedRowState } from '../../LineContext/Context'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
const CargoSWFn = ({
  cargoSWmodel,
  setCargoSWModel,
  selectedArray,
  setRequestPayload,
  handleConfirmDelay,
  user,
  closeCRModal
}) => {
  const [cargodate, setCargoDate] = useState()
  const [bookingdate, setBookingDate] = useState()
  const [inputcargosw, setinputCargoSW] = useState('')
  const [inputBookingsw, setinputBookingSW] = useState('')
  const [requestCargoSW, setRequestCargoSW] = useState('')
  const [requestBookingSW, setRequestBookingSW] = useState('')
  const [validationMessage, setValidationMessage] = useState('')
  const [validCargoSW, setValidCArgoSW] = useState(false)
  const [validBookingSW, setValidbookingSW] = useState(false)
  const [showConfirmModel, setShowConfirmmodel] = useState(false)
  const inputRef = useRef(null)
  const datePickerRefCARGO = useRef()
  const datePickerRefBooking = useRef()
  const { t } = useTranslation()
  const { CreateBookingRequest, CurrentSW, BulkOrderSelected, SelectCargoReadySW, EnterCargoReadySW, SelectBookingSW, EnterValidSW, EnterBookingSW, Submit, Cancel } = t('cargoswpage')

  const handleCargoSW = (date) => {
    if (date) {
      // Ensure date is not null
      setCargoDate(date)
      setRequestCargoSW(getWeek(date))
      setinputCargoSW(getWeek(date))
    }
  }
  const handleBookingSW = (date) => {
    if (date) {
      // Ensure date is not null
      setBookingDate(date)
      setRequestBookingSW(getWeek(date))
      setinputBookingSW(getWeek(date))
    }
  }
  const handleCreateChangeRequest = () => {
    if (requestBookingSW?.length > 0 || requestCargoSW?.length > 0) {
      const req_data = []
      const payload = selectedArray?.map((item) => {
        const req_obj = {
          id_po_supplier_portal: 1,
          po_number: Number(item?.PO_NUMBER),
          po_line_number: 1,
          revision_number: Number(item?.['REV#']),
          sku: item?.SKU,
          upc: item?.UPC,
          vendor_number: item?.['VENDOR#'],
          vendor_name: item?.VENDOR_NAME,
          request_type: `Booking`,
          description: item?.DESCRIPTION,
          original_dc: item?.DC,
          requested_dc: '',
          reason_code_dc: '',
          original_ship_week: item?.ORG_SW,
          requested_ship_week: '',
          reason_code_ship_week: '',
          current_ship_week: item?.CURRENT_SW,
          reason_code_ship_week: '',
          // reason_code_ship_week: reasonCodeValue.join('/'),
          original_qty: item?.QUANTITY,
          requested_qty: item?.QUANTITY,
          reason_code_qty: '',
          hft_user_id: 0,
          created_by: item?.VENDOR_NAME,
          supplier_contact_id: user,
          supplier_response: 'Vendor Initiated',
          status: 'CLOSED',
          qtyOpen: item?.OPEN_QTY,
          followups: 1,
          ontimetarget: item?.ONTARGET_SHIP_WK,
          inventory_analayst: item?.INVENTORY_ANALYST,
          shipweekdelay: item?.DELAY,
          cargoReadySW: requestCargoSW ? requestCargoSW : item?.CARGO_READY_SW,
          bookingSW: requestBookingSW ? requestBookingSW : item?.BOOKING_SW,
          vendor_manager: item?.VENDOR_MANAGER,
          unitCost: item?.UNIT_COST,
          extendedCost: item?.EXTENDED_COST
        }
        req_data.push(req_obj)
      })
      setShowConfirmmodel(true)
      setRequestPayload(req_data)
    } else {

      alert(i18next.t('VendorPopUp.pleaseselectanyoneshipweek'))
    }
  }
  const resetValues = () => {
    setCargoSWModel(false)
    setRequestBookingSW('')
    setRequestCargoSW('')
    setCargoDate(getDateofMonday(selectedArray[0]?.CURRENT_SW))
    setBookingDate(getDateofMonday(selectedArray[0]?.CURRENT_SW))
    setinputBookingSW('')
    setinputCargoSW('')
    setValidCArgoSW(false)
    setValidCArgoSW(false)
    closeCRModal()
    setShowConfirmmodel(false)
  }
  const handleSubmit = () => {
    resetValues()
    handleConfirmDelay()
  }

  useEffect(() => {
    if (selectedArray?.length > 0) {
      setinputBookingSW(selectedArray[0]?.BOOKING_SW || '')
      setinputCargoSW(selectedArray[0]?.CARGO_READY_SW || '')
      setCargoDate(getDateofMonday(getPossibleShipWeek(selectedArray[0]?.CARGO_READY_SW ? selectedArray[0]?.CARGO_READY_SW : selectedArray[0]?.CURRENT_SW)))
      setBookingDate(getDateofMonday(getPossibleShipWeek(selectedArray[0]?.BOOKING_SW ? selectedArray[0]?.BOOKING_SW : selectedArray[0]?.CURRENT_SW)))

    }
  }, [selectedArray])

  useEffect(() => {
    if (cargoSWmodel && inputRef.current) {
      inputRef.current.focus()
    }
  }, [cargoSWmodel])

  return (
    <>
      <dialog
        open={cargoSWmodel}
        className='p-3 inset-0 fixed z-20 bg-black bg-opacity-50 w-full min-h-screen'
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center'>
          <div className='sm:w-[400px] w-[280px] min-h-[300px] bg-white p-4 rounded-md flex flex-col gap-6 text-xs'>
            <div className='w-full flex justify-end items-center'>
              <button
                className='border-2 border-gray-300 p-2 rounded-md text-sm'
                onClick={resetValues}
              >
                ❌
              </button>
            </div>
            <div>
              <p className='font-medium text-base'>{CreateBookingRequest}</p>
            </div>
            <div className=' items-center font-medium pl-4'>
              <p>{CurrentSW}</p>
              {(selectedArray.length === 1 && <p className='border p-1 mt-1 text-xs w-[156px] bg-gray-300'>
                {selectedArray[0]?.CURRENT_SW}
              </p>)}
              {(selectedArray.length > 1 && <p className='mt-1 text-xs text-red-500'>{BulkOrderSelected}</p>)}
            </div>
            <form
              // onSubmit={handleCreateChangeRequest}
              className='w-full flex flex-col  gap-4 justify-between items-center'
            >
              <div className='flex items-center font-medium gap-5'>
                <div className=' items-center font-medium'>
                  <p>{SelectCargoReadySW}</p>
                  <DatePicker
                    editable={true}
                    showWeekNumbers
                    filterDate={(date) => date.getDay() === 1}
                    selected={cargodate}
                    onChange={(date) => handleCargoSW(date)}
                    className='p-1 mt-1 border w-full bg-yellow-300'
                    calendarStartDay={1}
                    showYearDropdown
                    ref={datePickerRefCARGO}
                    minDate={
                      getSwWithPresent()
                    }
                    highlightDates={highLightDateField(
                      selectedArray[0]?.CURRENT_SW
                    )}
                    openToDate={OpenSpecificDate(
                      requestCargoSW ? requestCargoSW :
                        selectedArray[0]?.CARGO_READY_SW ? selectedArray[0]?.CARGO_READY_SW : selectedArray[0]?.CURRENT_SW
                    )}
                    weekNumbersClassName='text-blue-800'
                  />
                </div>
                <div>
                  <p>{EnterCargoReadySW}</p>
                  <input
                    ref={inputRef}
                    type='text'
                    className='border p-1 mt-1 text-xs w-[156px] bg-yellow-300 placeholder-black'
                    placeholder='SW.YY'
                    value={inputcargosw}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9.]/g, '')
                      setinputCargoSW(value)
                      // if (value === selectedArray[0]?.CURRENT_SW) {
                      //   setValidCArgoSW(false)
                      //   setValidationMessage('')
                      //   const sw = getPossibleShipWeek(value)
                      //   setRequestCargoSW(sw)
                      //   setCargoDate(getDateofMonday(sw))
                      //   datePickerRefCARGO.current.setFocus()
                      //   return
                      // }
                      const validation = validateSWforBooking(
                        value,
                        selectedArray[0]?.CURRENT_SW
                      )

                      if (!validation.isValid) {
                        setValidCArgoSW(true)
                        setValidationMessage(validation.message)
                      } else {
                        setValidCArgoSW(false)
                        setValidationMessage('')
                        const sw = getPossibleShipWeek(value)
                        setRequestCargoSW(makeSWformat(sw))
                        setCargoDate(getDateofMonday(sw))
                        datePickerRefCARGO.current.setFocus()
                      }
                      // if (value.length === 5) {

                      // } else {
                      //   setValidCArgoSW(false) // Reset message if input is incomplete
                      //   setValidationMessage('')
                      //   setRequestCargoSW('')
                      // }
                    }}
                  />
                </div>
              </div>
              <div class='h-px my-2 bg-gray-500 border-1 w-full ' />

              <div className='flex items-center font-medium gap-5'>
                <div>
                  <p>{SelectBookingSW}</p>
                  <DatePicker
                    editable={true}
                    showWeekNumbers
                    filterDate={(date) => date.getDay() === 1}
                    selected={bookingdate}
                    onChange={(date) => handleBookingSW(date)}
                    className='p-1 mt-1 border w-full bg-yellow-300'
                    calendarStartDay={1}
                    showYearDropdown
                    ref={datePickerRefBooking}
                    minDate={
                      getSwWithPresent()
                    }
                    highlightDates={highLightDateField(
                      selectedArray[0]?.CURRENT_SW
                    )}
                    openToDate={OpenSpecificDate(requestBookingSW ? requestBookingSW :
                      selectedArray[0]?.BOOKING_SW ? selectedArray[0]?.BOOKING_SW : selectedArray[0]?.CURRENT_SW)}
                    weekNumbersClassName='text-blue-800'
                  />
                </div>
                <div>
                  <p>{EnterBookingSW}</p>
                  <input
                    type='text'
                    className='border p-1 mt-1 text-xs w-[156px] bg-yellow-300 placeholder-black'
                    placeholder='SW.YY'
                    value={inputBookingsw}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9.]/g, '')
                      setinputBookingSW(value)
                      const validation = validateSWforBooking(
                        value,
                        selectedArray[0]?.CURRENT_SW
                      )

                      if (!validation.isValid) {
                        setValidbookingSW(true)
                        setValidationMessage(validation.message)
                      } else {
                        setValidbookingSW(false)
                        setValidationMessage('')
                        const sw = getPossibleShipWeek(value)
                        setRequestBookingSW(makeSWformat(sw))
                        setBookingDate(getDateofMonday(sw))
                        datePickerRefBooking.current.setFocus()
                      }
                      // if (value.length === 5) {

                      // } else {
                      //   setValidbookingSW(false) // Reset message if input is incomplete
                      //   setValidationMessage('')
                      //   setRequestBookingSW('')
                      // }
                    }}
                  />
                </div>
              </div>
              {(validBookingSW || validCargoSW) && (
                <span className='text-red-500'>{EnterValidSW}</span>
              )}
            </form>
            <div className='w-full flex justify-center items-center gap-6 text-white font-medium mt-5'>
              <button
                className={`px-4 h-[30px] bg-green-500 rounded-md  
                                    ${validBookingSW || validCargoSW || (requestCargoSW?.length === 0 && requestBookingSW?.length === 0) ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                type='submit'
                onClick={handleCreateChangeRequest}
                disabled={
                  validBookingSW ||
                  validCargoSW ||
                  (requestCargoSW?.length === 0 &&
                    requestBookingSW?.length === 0)
                }
              >
                {Submit}
              </button>
              <PopupModal
                isOpen={showConfirmModel}
                message={i18next.t('VendorPopUp.Doyouwanttocreateabookingrequest')}

                onCancel={() => setShowConfirmmodel(false)}
                cancelText={i18next.t('AcceptModal.Cancel')}
                onConfirm={handleSubmit}
                confirmText={i18next.t('AcceptModal.Confirm')}
              />
              <button
                className='px-4 h-[30px] bg-gray-500 text-white rounded-md'
                type='button'
                onClick={resetValues}
              >
                {Cancel}
              </button>
            </div>
          </div>
        </div>
      </dialog>
    </>
  )
}
export default CargoSWFn

export const CorgoSWfnLineLevel = ({
  row,
  selectedArray,
  handleConfirmDelay,
  updateRowSelection
}) => {
  const [cargodate, setCargoDate] = useState()
  const [bookingdate, setBookingDate] = useState()
  const [inputcargosw, setinputCargoSW] = useState('')
  const [inputBookingsw, setinputBookingSW] = useState('')
  const [requestCargoSW, setRequestCargoSW] = useState('')
  const [requestBookingSW, setRequestBookingSW] = useState('')
  const [validationMessage, setValidationMessage] = useState('')
  const [validCargoSW, setValidCArgoSW] = useState(false)
  const [validBookingSW, setValidbookingSW] = useState(false)
  const [showConfirmModel, setShowConfirmmodel] = useState(false)
  const [cargoSWLinemodel, setCargoSWLineModel] = useState(false)
  const [originalBookingSW, setOriginalBookingSW] = useState('')
  const [originalCargoSW, setOriginalCargoSW] = useState('')
  const inputRef = useRef(null)
  const datePickerRefCARGO = useRef()
  const datePickerRefBooking = useRef()
  // const inputRef = useRef(null)
  const { t } = useTranslation()
  const { CreateBookingRequest, CurrentSW, BulkOrderSelected, SelectCargoReadySW, EnterCargoReadySW, SelectBookingSW, EnterValidSW, EnterBookingSW, Submit, Cancel } = t('cargoswpage')
  const {
    state: { editArrayBookingSW },
    dispatch
  } = EditedRowState()
  const handleCargoSW = (date) => {
    if (date) {
      // Ensure date is not null
      setCargoDate(date)
      setRequestCargoSW(getWeek(date))
      setinputCargoSW(getWeek(date))
    }
  }
  const handleBookingSW = (date) => {
    if (date) {
      // Ensure date is not null
      setBookingDate(date)
      setRequestBookingSW(getWeek(date))
      setinputBookingSW(getWeek(date))
    }
  }

  const resetValues = () => {
    setCargoSWLineModel(false)
    setRequestBookingSW('')
    setRequestCargoSW('')

    setCargoDate(getDateofMonday(getPossibleShipWeek(row?.original?.CARGO_READY_SW ? row?.original?.CARGO_READY_SW : row?.original?.CURRENT_SW)))
    setBookingDate(getDateofMonday(getPossibleShipWeek(row?.original?.BOOKING_SW ? row?.original?.BOOKING_SW : row?.original?.CURRENT_SW)))

    setinputBookingSW(originalBookingSW)
    setinputCargoSW(originalCargoSW)
    setValidCArgoSW(false)
    setValidCArgoSW(false)
    // closeCRModal()
    setShowConfirmmodel(false)
  }
  const handleUpdate = () => {
    const updatedRow = {
      ...row.original,
      BOOKING_SW: requestBookingSW ? requestBookingSW : row.original.BOOKING_SW,
      CARGO_SW: requestCargoSW ? requestCargoSW : row.original?.CARGO_READY_SW
    }
    dispatch({
      type: 'ADD_CARGO_SW',
      payload: updatedRow
    })
    const updatedRowId = row.id
    updateRowSelection((prevSelection) => ({
      ...prevSelection,
      [updatedRowId]: true
    }))
    setCargoSWLineModel(false)
  }

  const resetValue = () => {
    resetValues()
    setRequestBookingSW('')
    setRequestCargoSW('')
    dispatch({
      type: 'REMOVE_CARGO_SW',
      payload: {
        PO_SKU: row?.original?.PO_SKU
      }
    })
    updateRowSelection((prevSelection) => ({
      ...prevSelection,
      [row.id]: false
    }))
  }

  const checkIsAvaialble = () => {
    return editArrayBookingSW?.some((c) => c?.PO_SKU === row?.original?.PO_SKU)
  }
  const checkPOSKU = () => {
    return editArrayBookingSW?.find((c) => c?.PO_SKU === row?.original?.PO_SKU)
  }
  const handleEdit = () => {
    if (editArrayBookingSW?.some((c) => c?.PO_SKU === row?.original?.PO_SKU)) {
      alert(i18next.t('VendorPopUp.YouhavealreadychangedavalueinthisPOLine'))
    }
    else if (
      row?.original?.CURRENT_SW == null &&
      row?.original?.ORG_SW === null
    ) {
      alert(i18next.t(`VendorPopUp.Polinedoesnthavecurrentconfirmshipweek`))
    }
    else {
      setCargoSWLineModel(true)
    }
  }
  useEffect(() => {
    if (row?.original?.CURRENT_SW) {
      setOriginalBookingSW(row?.original?.BOOKING_SW || '')
      setOriginalCargoSW(row?.original?.CARGO_READY_SW || '')
      setinputBookingSW(row?.original?.BOOKING_SW || '')
      setinputCargoSW(row?.original?.CARGO_READY_SW || '')
      setCargoDate(getDateofMonday(getPossibleShipWeek(row?.original?.CARGO_READY_SW ? row?.original?.CARGO_READY_SW : row?.original?.CURRENT_SW)))
      setBookingDate(getDateofMonday(getPossibleShipWeek(row?.original?.BOOKING_SW ? row?.original?.BOOKING_SW : row?.original?.CURRENT_SW)))
      setRequestCargoSW(checkPOSKU() ? checkPOSKU()?.CARGO_SW : row?.original?.CARGO_READY_SW)
      setRequestBookingSW(checkPOSKU() ? checkPOSKU()?.BOOKING_SW : row?.original?.BOOKING_SW)
    }
  }, [row])

  useEffect(() => {
    if (cargoSWLinemodel && inputRef.current) {
      inputRef.current.focus()
    }
  }, [cargoSWLinemodel])

  return (
    <div>
      <div className='flex justify-center items-center'>
        <p className='cursor-pointer p-2' onClick={handleEdit}>
          {checkIsAvaialble() ? (
            <div className='flex space-x-3'>
              <p className=' text-xs'>
                Booking SW{' '}
                <span className='font-bold p-[0.5]'>
                  {requestBookingSW
                    ? requestBookingSW
                    : row?.original?.BOOKING_SW}
                </span>
              </p>
              <p className='text-xs'>
                Cargo SW{' '}
                <span className='font-bold'>
                  {requestCargoSW
                    ? requestCargoSW
                    : row?.original?.CARGO_READY_SW}
                </span>
              </p>
            </div>
          ) : (
            row?.original?.BOOKING_SW
          )}
        </p>
        {checkIsAvaialble() && (
          <div className='w-full justify-end items-center flex'>
            <button onClick={() => resetValue()}>
              <svg
                className='w-6 h-6 text-gray-800 dark:text-white'
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='currentColor'
                viewBox='0 0 24 24'
              >
                <path
                  fillRule='evenodd'
                  d='M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm7.7-3.7a1 1 0 0 0-1.4 1.4l2.3 2.3-2.3 2.3a1 1 0 1 0 1.4 1.4l2.3-2.3 2.3 2.3a1 1 0 0 0 1.4-1.4L13.4 12l2.3-2.3a1 1 0 0 0-1.4-1.4L12 10.6 9.7 8.3Z'
                  clipRule='evenodd'
                />
              </svg>
            </button>
          </div>
        )}
      </div>
      <dialog
        open={cargoSWLinemodel}
        className='p-3 inset-0 fixed z-20 bg-black bg-opacity-50 w-full min-h-screen'
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center text-left'>
          <div className='sm:w-[400px] w-[280px] min-h-[300px] bg-white p-4 rounded-md flex flex-col gap-6 text-xs'>
            <div className='w-full flex justify-end items-center'>
              <button
                className='border-2 border-gray-300 p-2 rounded-md text-sm'
                onClick={resetValues}
              >
                ❌
              </button>
            </div>
            <div>
              <p className='font-medium text-base'>{CreateBookingRequest}</p>
            </div>
            <div className=' items-center font-medium pl-4'>
              <p>{CurrentSW}</p>
              <p className='border p-1 mt-1 text-xs w-[156px] bg-gray-300'>
                {row?.original?.CURRENT_SW}
              </p>
            </div>
            <form
              // onSubmit={handleCreateChangeRequest}
              className='w-full flex flex-col  gap-4 justify-between items-center'
            >
              <div className='flex items-center font-medium gap-5'>
                <div className=' items-center font-medium'>
                  <p>{SelectCargoReadySW}</p>
                  <DatePicker
                    editable={true}
                    showWeekNumbers
                    filterDate={(date) => date.getDay() === 1}
                    selected={cargodate}
                    onChange={(date) => handleCargoSW(date)}
                    className='p-1 mt-1 border w-full bg-yellow-300'
                    calendarStartDay={1}
                    showYearDropdown
                    ref={datePickerRefCARGO}
                    minDate={
                      getSwWithPresent()
                    }
                    weekNumbersClassName='text-blue-800'
                    highlightDates={highLightDateField(
                      row?.original?.CURRENT_SW
                    )}
                    openToDate={OpenSpecificDate(requestCargoSW ? requestCargoSW :
                      row?.original?.CARGO_READY_SW ? row?.original?.CARGO_READY_SW : row?.original?.CURRENT_SW
                    )}
                  />
                </div>
                <div>
                  <p>{EnterCargoReadySW}</p>
                  <input
                    ref={inputRef}
                    type='text'
                    className='border p-1 mt-1 text-xs w-[156px] bg-yellow-300 placeholder-black'
                    placeholder='SW.YY'
                    value={inputcargosw}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9.]/g, '')
                      setinputCargoSW(value)
                      // if (value === row?.original?.CURRENT_SW) {
                      //   setValidCArgoSW(false)
                      //   setValidationMessage('')
                      //   const sw = getPossibleShipWeek(value)
                      //   setRequestCargoSW(sw)
                      //   setCargoDate(getDateofMonday(sw))
                      //   datePickerRefCARGO.current.setFocus()
                      //   return
                      // }
                      const validation = validateSWforBooking(
                        value,
                        row?.original?.CURRENT_SW
                      )

                      if (!validation.isValid) {
                        setValidCArgoSW(true)
                        setValidationMessage(validation.message)
                      } else {
                        setValidCArgoSW(false)
                        setValidationMessage('')
                        const sw = getPossibleShipWeek(value)
                        setRequestCargoSW(makeSWformat(sw))
                        setCargoDate(getDateofMonday(sw))
                        datePickerRefCARGO.current.setFocus()
                      }
                      // if (value.length === 5) {

                      // } else {
                      //   setValidCArgoSW(false) // Reset message if input is incomplete
                      //   setValidationMessage('')
                      //   setRequestCargoSW('')
                      // }
                    }}
                  />
                </div>
              </div>
              <div class='h-px my-2 bg-gray-500 border-1 w-full ' />

              <div className='flex items-center font-medium gap-5'>
                <div>
                  <p>{SelectBookingSW}</p>
                  <DatePicker
                    editable={true}
                    showWeekNumbers
                    filterDate={(date) => date.getDay() === 1}
                    selected={bookingdate}
                    onChange={(date) => handleBookingSW(date)}
                    className='p-1 mt-1 border w-full bg-yellow-300'
                    calendarStartDay={1}
                    showYearDropdown
                    ref={datePickerRefBooking}
                    minDate={
                      getSwWithPresent()
                    }
                    weekNumbersClassName='text-blue-800'
                    highlightDates={highLightDateField(
                      row?.original?.CURRENT_SW
                    )}
                    openToDate={OpenSpecificDate(requestBookingSW ? requestBookingSW :
                      row?.original?.BOOKING_SW ? row?.original?.BOOKING_SW : row?.original?.CURRENT_SW
                    )}
                  />
                </div>
                <div>
                  <p>{EnterBookingSW}</p>
                  <input
                    type='text'
                    className='border p-1 mt-1 text-xs w-[156px] bg-yellow-300 placeholder-black'
                    placeholder='SW.YY'
                    value={inputBookingsw}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9.]/g, '')
                      setinputBookingSW(value)
                      // if (value === row?.original?.CURRENT_SW) {
                      //   setValidbookingSW(false)
                      //   setValidationMessage('')
                      //   const sw = getPossibleShipWeek(value)
                      //   setRequestBookingSW(sw)
                      //   setBookingDate(getDateofMonday(sw))
                      //   datePickerRefBooking.current.setFocus()
                      //   return
                      // }
                      const validation = validateSWforBooking(
                        value,
                        row?.original?.CURRENT_SW
                      )

                      if (!validation.isValid) {
                        setValidbookingSW(true)
                        setValidationMessage(validation.message)
                      } else {
                        setValidbookingSW(false)
                        setValidationMessage('')
                        const sw = getPossibleShipWeek(value)
                        setRequestBookingSW(makeSWformat(sw))
                        setBookingDate(getDateofMonday(sw))
                        datePickerRefBooking.current.setFocus()
                      }
                      // if (value.length === 5) {

                      // } else {
                      //   setValidbookingSW(false) // Reset message if input is incomplete
                      //   setValidationMessage('')
                      //   setRequestBookingSW('')
                      // }
                    }}
                  />
                </div>
              </div>
              {(validBookingSW || validCargoSW) && (
                <span className='text-red-500'>{EnterValidSW}</span>
              )}
            </form>
            <div className='w-full flex justify-center items-center gap-6 text-white font-medium mt-5'>
              <button
                className={`px-4 h-[30px] bg-green-500 rounded-md  
                                    ${validBookingSW || validCargoSW || (requestCargoSW?.length === 0 && requestBookingSW?.length === 0) ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                type='submit'
                onClick={handleUpdate}
                disabled={
                  validBookingSW ||
                  validCargoSW ||
                  (requestCargoSW?.length === 0 &&
                    requestBookingSW?.length === 0)
                }
              >
                {Submit}
              </button>
              <button
                className='px-4 h-[30px] bg-gray-500 text-white rounded-md'
                type='button'
                onClick={resetValues}
              >
                {Cancel}
              </button>
            </div>
          </div>
        </div>
      </dialog>
    </div>
  )
}

//this function for to get date if monday occur on current it will return next monday date otherwise same date
export const getSwWithPresent = () => {
  let date = new Date()
  if (date.getDay() === 1)
    date.setDate(date.getDate() + 7)
  return date

}
