import React, { useState, useEffect, useRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  changeDateFormat,
  getDateofMonday,
  getPossibleShipWeek,
  getPresentShipWeek,
  getStartDate,
  makeSWformat,
  nullOriginal,
  reasonCodes as reason_codes,
  reasonCodesEN,
  reasonCodesMN,
  updateActionHistory,
  ValidateAPI
} from '../../utils/Utils'
import { getWeek } from '../../screens/buyer/NewPoList'
import axios from 'axios'
import { weeksInBetween } from '../../screens/vendor/ConfirmPo'
import Loading from '../Loading'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import PopupModal from '../PopupModal'
import PopupBanner from '../PopupBanner'
import ReasonCodeList, { ReasonCodeView } from '../ReasonCodeList'

const ExpediteActionComp = ({
  requestData,
  setShowChangeRequestBanner,
  setMessageForBanner,
  setShowAlert
}) => {
  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [inputsw, setinputsw] = useState('')

  const [showAcceptDatePicker, setShowAcceptDatePicker] = useState(false)
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [date, setDate] = useState()
  const [requestSW, setRequestSW] = useState(requestData?.requested_ship_week)

  const dc_list = ['CAM', 'DAL', 'DIL', 'MOR', 'CHI']
  const [reasonCodeValue, setreasonCodeValue] = useState('')
  const [submitting, setSubmitting] = useState(false)
  // const [showChangeRequestBanner, setShowChangeRequestBanner] = useState(false)//notifybar
  const user = JSON.parse(localStorage.getItem('userData'))
  const [showAcceptRequestModal, setShowAcceptRequestModal] = useState(false)
  const [poRow, setPoRow] = useState('')
  const [crIdResponse, setCrIdResponse] = useState('')
  const [checkValid, setCheckValid] = useState(false)
  const [checkPastDue, setCheckPastDue] = useState(false)
  const { t } = useTranslation()
  const datePickerRef = useRef(null)
  const {
    acceptPOChangerequest,
    ProposeNewSWDate, ChangedSWDate, EntervalidSW, ProposeNewSW, ChangedSW, CurrentSW,
    ORGSW,
    ChangeSW,
    SelectedShipweek,
    DC,
    Cancel,
    ChangeDC,
    Changetheshipweek,
    SelectShipweek,
    Confirm,
    cancelReasonCode,
    Selectareason,
    Reject,
    Submitting_load
  } = t('ExpediteActionComp')

  const handleChangeDate = (date) => {
    setDate(date)
    // setRequestSW(getWeek(date))
    setinputsw(getWeek(date))
  }
  const closePopUpModal = () => {
    setShowChangeRequestBanner(false)
  }

  const closeAcceptRequestModal = () => {
    setShowAcceptRequestModal(false)
  }

  const openAcceptRequestModal = () => {
    setShowAcceptRequestModal(true)
  }

  const handleAcceptRequest = () => {
    // setShowChangeRequestBanner(true);
    // if (window.confirm("Are you sure you want to confirm?")) {
    let payload = []
    if (requestData?.request_type !== 'Divert') {
      payload = [
        {
          id_open_request: requestData?.id_open_request,
          id_po_supplier_portal: Number(requestData?.id_po_supplier_portal),
          original_dc: requestData?.original_dc,
          requested_dc: requestData?.requested_dc,
          // original_ship_week: showAcceptDatePicker
          //   ? requestSW
          //   : Number(requestData?.requested_ship_week),
          original_ship_week: Number(requestData?.original_ship_week),
          requested_ship_week: Number(requestData?.requested_ship_week),
          original_qty: requestData?.original_qty,
          requested_qty: requestData?.requested_qty,
          inventory_analyst_id: 1,
          current_ship_week: showAcceptDatePicker
            ? inputsw
            : Number(requestData?.requested_ship_week),
          vendor_selected_sw: showAcceptDatePicker
            ? inputsw
            : Number(requestData?.requested_ship_week),
          supplier_contact_id: user?.vendorContactOrcaleId,
          on_time_target: showAcceptDatePicker
            ? inputsw
            : requestData?.requested_ship_week,
          // ship_week_delay: Number(
          //   showAcceptDatePicker
          //     ? Math.abs(
          //         weeksInBetween(
          //           requestData?.requested_ship_week ??
          //             requestData?.original_ship_week,
          //           requestSW
          //         )
          //       )
          //     : 0
          // ),
          ship_week_delay: Number(
            showAcceptDatePicker
              ? Math.abs(
                weeksInBetween(
                  showAcceptDatePicker
                    ? requestSW
                    : Number(requestData?.requested_ship_week),
                  showAcceptDatePicker
                    ? requestSW
                    : requestData?.requested_ship_week
                )
              )
              : 0
          )
        }
      ]
    } else {
      payload = [
        {
          id_open_request: requestData?.id_open_request,
          id_po_supplier_portal: Number(requestData?.id_po_supplier_portal),
          original_dc: requestData?.original_dc,
          requested_dc: requestData?.requested_dc,
          // original_ship_week: showAcceptDatePicker
          //   ? requestSW
          //   : Number(requestData?.requested_ship_week),
          original_ship_week: Number(requestData?.original_ship_week),
          requested_ship_week: Number(requestData?.requested_ship_week),
          original_qty: requestData?.original_qty,
          requested_qty: requestData?.requested_qty,
          inventory_analyst_id: 1,
          current_ship_week: requestData?.current_ship_week,
          vendor_selected_sw: requestData?.vendor_selected_sw,
          supplier_contact_id: user?.vendorContactOrcaleId,
          on_time_target: requestData?.on_time_target,
          // ship_week_delay: Number(
          //   showAcceptDatePicker
          //     ? Math.abs(
          //         weeksInBetween(
          //           requestData?.requested_ship_week ??
          //             requestData?.original_ship_week,
          //           requestSW
          //         )
          //       )
          //     : 0
          // ),
          ship_week_delay: Number(
            showAcceptDatePicker
              ? Math.abs(
                weeksInBetween(
                  showAcceptDatePicker
                    ? requestSW
                    : Number(requestData?.requested_ship_week),
                  showAcceptDatePicker
                    ? requestSW
                    : requestData?.requested_ship_week
                )
              )
              : 0
          )
        }
      ]
    }
    console.log('payload before accept request: ', payload)
    setSubmitting(true)
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-accept-request`,
        payload,
        ValidateAPI()
      )
      .then((response) => {
        console.log('response from accept request: ', response?.data)
        if (response?.data) {
          const crIdResponse = response.data.map((item) => item.id_open_request)
          const poRow = response.data.map((item) => item.po_number)

          console.log('crIdResponse:', crIdResponse)
          console.log('poNumber:', poRow)

          setCrIdResponse(crIdResponse)
          setPoRow(poRow)
        }

        updateActionHistory('Accepted', [requestData])
        setSubmitting(false)
        setShowAcceptModal(false)
        setShowChangeRequestBanner(true)
        setMessageForBanner(i18next.t('VendorPopUp.ChangeRequestSubmittedSuccessfully'))
        // window?.location?.reload();
      })
      .catch((error) => {
        console.log('error while accepting req: ', error)
        setSubmitting(false)
      })
  }

  const handleRejectRequest = () => {
    if (!reasonCodeValue?.length > 0)
      return alert(i18next.t('VendorPopUp.Pleaseselectareasoncode'))

    const payload = [
      {
        id_open_request: requestData?.id_open_request,
        id_po_supplier_portal: requestData?.id_po_supplier_portal,
        original_dc: requestData?.original_dc,
        requested_dc: requestData?.requested_dc,
        original_ship_week: requestData?.original_ship_week,
        requested_ship_week: requestData?.requested_ship_week,
        reason_code_ship_week:
          requestData?.request_type !== 'Divert' ? reasonCodeValue : '',
        reason_code_dc:
          requestData?.request_type === 'Divert' ? reasonCodeValue : '',
        original_qty: requestData?.original_qty,
        requested_qty: requestData?.original_qty,
        reason_code_qty: '',
        inventory_analyst_id: 1,
        supplier_contact_id: user?.vendorContactOrcaleId,
        current_ship_week: requestData?.current_ship_week,
        on_time_target: requestData?.on_time_target,
        status: 'CLOSED'
      }
    ]
    console.log('payload before reject request: ', payload)
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-reject-request`,
        payload,
        ValidateAPI()
      )
      .then((response) => {
        console.log('response from reject request: ', response?.data)
        updateActionHistory('Rejected', [requestData])
        setShowRejectModal(false)
        if (response?.data) {
          const crIdResponse = response.data.map((item) => item.id_open_request)
          const poRow = response.data.map((item) => item.po_number)

          console.log('crIdResponse:', crIdResponse)
          console.log('poNumber:', poRow)

          setCrIdResponse(crIdResponse)
          setPoRow(poRow)
        }

        setShowChangeRequestBanner(true)
        setMessageForBanner(i18next.t('VendorPopUp.ChangeRequestSubmittedSuccessfully'))
        // window?.location?.reload()
      })
      .catch((error) => {
        console.log('error while rejecting req: ', error)
      })
  }

  const CheckNewVAlue = (type) => {
    console.log('hi')
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-cr-by-id?idOpenRequest=${requestData?.id_open_request}`,
        ValidateAPI()
      )
      .then((response) => {
        console.log('response from request: ', response?.data)
        if (requestData?.request_type === 'Divert') {
          if (requestData?.requested_dc !== response?.data.requested_dc) {
            setShowAlert(true)
            setMessageForBanner(
              i18next.t(' VendorPopUp.Thischangerequestisupdatedpleasereviewthechange')
            )
          } else if (type === 'accept') {
            setShowAcceptModal(true)
          } else if (type === 'reject') {
            setShowRejectModal(true)
          }
        } else if (
          requestData?.request_type === 'Expedite' ||
          requestData?.request_type === 'Defer'
        ) {
          if (
            requestData?.requested_ship_week !==
            response?.data.requested_ship_week
          ) {
            setShowAlert(true)
            setMessageForBanner(
              i18next.t(' VendorPopUp.Thischangerequestisupdatedpleasereviewthechange')
            )
          } else if (type === 'accept') {
            setShowAcceptModal(true)
          } else if (type === 'reject') {
            setShowRejectModal(true)
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          // navigate('/login-error')
        }
      })
  }
  useEffect(() => {
    if (!requestData?.request_type?.includes('Expedite'))
      setDate(getDateofMonday(getPossibleShipWeek(requestData?.current_ship_week)))
    else {
      setDate(getDateofMonday(getPossibleShipWeek(requestData?.requested_ship_week)))
    }
  }, requestData?.current_ship_week)


  const inputRef = useRef(null);

useEffect(() => {
  if ((showAcceptDatePicker || showAcceptModal) && inputRef.current) {
    inputRef.current.focus();
  }
}, [showAcceptDatePicker, showAcceptModal]);
  return (
    <div className='w-full flex justify-center items-center gap-2'>
      <div className='flex gap-12 justify-center items-center'>
        <div className="h-6 border-l border-black"></div>
        <button onClick={() => CheckNewVAlue('accept')}>
          <svg
            className='w-6 h-6 text-green-600 '
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='currentColor'
            viewBox='0 0 20 20'
          >
            <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z' />
          </svg>
        </button>
        <div className="h-6 border-l border-black"></div>
        <button onClick={() => CheckNewVAlue('reject')}>
          <svg
            className='w-6 h-6 text-red-500'
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='currentColor'
            viewBox='0 0 20 20'
          >
            <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z' />
          </svg>
        </button>
        <div className="h-6 border-l border-black"></div>
      </div>
      {/*Accept Request Dialog Box */}
      <dialog
        open={showAcceptModal}
        className='p-3 inset-0 fixed z-50 bg-black bg-opacity-50 w-full min-h-screen'
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center'>
          {submitting ? (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <Loading type={Submitting_load} />
            </div>
          ) : (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <div className='w-full flex justify-end items-center'>
                <button
                  className='border-2 border-gray-300 p-2 rounded-md text-sm'
                  onClick={() => setShowAcceptModal(false)}
                >
                  ❌
                </button>
              </div>
              <div className='w-full flex justify-center items-center mt-2 text-center'>
                <p className='text-sm font-medium'>{acceptPOChangerequest}</p>
              </div>
              {requestData?.request_type &&
                (requestData.request_type.includes('Defer') ||
                  requestData.request_type.includes('Expedite') ? (
                  <div className='w-full p-2 flex justify-start'>
                    <div
                      className='flex gap-10 items-center'
                      style={{ width: 'fit-content' }}
                    >
                      <div>
                        <p className='font-medium text-xs text-left'>
                          {CurrentSW}
                        </p>
                        <p className='text-xs border p-1 w-[156px] text-left bg-gray-300 '>
                          {requestData.current_ship_week}
                        </p>
                      </div>
                      <div>
                        <p className='font-medium text-xs text-left'>
                          {ChangeSW}
                        </p>
                        <p className='text-xs border p-1 w-[156px] text-left bg-gray-300'>
                          {requestData.requested_ship_week}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  requestData.request_type.includes('Divert') && (
                    <div className='w-full p-3 flex justify-start'>
                      <div
                        className='flex gap-10 items-center'
                        style={{ width: 'fit-content' }}
                      >
                        <div>
                          <p className='font-medium text-xs text-left'>{DC}</p>
                          <p className='text-xs border p-1 w-[156px] text-left bg-gray-300'>
                            {requestData.original_dc}
                          </p>
                        </div>
                        <div>
                          <p className='font-medium text-xs text-left'>
                            {ChangeDC}
                          </p>
                          <p className='text-xs border p-1 w-[156px] text-left bg-gray-300'>
                            {requestData.requested_dc}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                ))}

              {requestData?.request_type !== 'Divert' && (
                <div className='text-xs flex gap-2'>
                  <input
                    type='checkbox'
                    name='date_change_cb'
                    id='date_change_cb'
                    value={showAcceptDatePicker}
                    onChange={(e) =>
                      setShowAcceptDatePicker(e?.target?.checked)
                    }
                  />
                  <label htmlFor='date_change_cb'>{Changetheshipweek}</label>
                </div>
              )}
              {showAcceptDatePicker && (
                <div className='w-full flex flex-col  gap-4 justify-between items-center'>
                  <div className='flex items-center gap-5 text-xs font-medium text-left'>
                    <div>
                      <p>{ChangedSW}</p>
                      <p className='mt-1 border p-1 w-[167px] text-left bg-gray-300'>
                        {requestSW}
                      </p>
                    </div>
                    <div>
                      <p>{ProposeNewSW}</p>
                      <input
                        ref={inputRef}
                        type='text'
                        className='border p-1 mt-1 text-xs w-[167px] placeholder-black bg-yellow-300'
                        placeholder='SW.YY(e.g.,00.00)'
                        value={inputsw}
                        onChange={(e) => {
                          const inputVal = e?.target?.value.replace(/[^0-9.]/g, '')
                          setinputsw(inputVal)
                          const regex = /^\d{1,2}\.\d{2}$/;
                          if (regex.test(inputVal)) {
                            const sw = getPossibleShipWeek(inputVal)
                            const [inputWeek, inputYear] = sw.split('.').map(Number)
                            const [currentWeek, currentYear] = requestData.current_ship_week.split('.').map(Number)
                            const [changeWeek, changeYear] = requestData.requested_ship_week.split('.').map(Number)

                            const toComparableNumber = (week, year) => {
                              const isLeapYear = (year) => {
                                return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
                              };

                              const getWeeksInYear = (year) => {
                                const firstDayOfYear = new Date(year, 0, 1);
                                const lastDayOfYear = new Date(year, 11, 31);
                                const firstDayOfNextYear = new Date(year + 1, 0, 1);

                                const firstWeekDay = firstDayOfYear.getDay() || 7; // Get day of the week (1 = Monday, ..., 7 = Sunday)
                                const lastWeekDay = lastDayOfYear.getDay() || 7;
                                const firstDayOfNextYearDay = firstDayOfNextYear.getDay() || 7;

                                const daysInFirstWeek = firstWeekDay <= 4 ? 7 - firstWeekDay + 1 : 0;
                                const daysInLastWeek = lastWeekDay >= 4 ? lastWeekDay : 0;
                                const daysInFirstWeekOfNextYear = firstDayOfNextYearDay <= 4 ? firstDayOfNextYearDay : 0;

                                const totalWeeks = Math.ceil((365 + (isLeapYear(year) ? 1 : 0) - daysInFirstWeek - daysInLastWeek + daysInFirstWeekOfNextYear) / 7);

                                return totalWeeks;
                              };

                              const weeksInYear = getWeeksInYear(year);

                              if (week > weeksInYear || week < 1) {
                                return null; // Return null for invalid week numbers
                              }

                              return year * 100 + week; // Use a different multiplier to avoid overlap
                            };

                            const inputComparable = toComparableNumber(inputWeek, inputYear)
                            const currentComparable = toComparableNumber(currentWeek, currentYear)
                            const changeComparable = toComparableNumber(changeWeek, changeYear)

                            console.log(`Input: ${inputWeek}.${inputYear}, Current: ${currentWeek}.${currentYear}, Change: ${changeWeek}.${changeYear}`);
                            console.log(`Comparable Numbers - Input: ${inputComparable}, Current: ${currentComparable}, Change: ${changeComparable}`);

                            if (inputComparable === null) {
                              setCheckValid(true);
                              console.log('Invalid week number');
                              return;
                            }

                            const minComparable = Math.min(currentComparable, changeComparable)
                            const maxComparable = Math.max(currentComparable, changeComparable)

                            if (inputComparable > minComparable && inputComparable < maxComparable) {
                              // Valid SW within range
                              setRequestSW(makeSWformat(sw))
                              setCheckValid(false)
                              setCheckPastDue(false)
                              const mondayDate = getDateofMonday(sw) // Calculate the date for the selected SW
                              setDate(mondayDate) // Set this date so DatePicker will open with this selected date
                              datePickerRef.current.setFocus()
                            } else if (!requestData?.request_type?.includes('Expedite') && inputComparable < maxComparable && inputComparable > minComparable) {
                              setRequestSW(makeSWformat(sw))
                              setCheckValid(false)
                              setCheckPastDue(false)
                              const mondayDate = getDateofMonday(sw) // Same as above for non-expedite requests
                              setDate(mondayDate)
                              datePickerRef.current.setFocus()
                            } else {
                              setCheckValid(true)
                              console.log('Out of range');
                            }
                          }
                          else {
                            setCheckValid(true)
                          }
                        }}
                      />
                    </div>
                  </div>
                  {/* above part */}
                  <div class='h-px my-2 bg-gray-500 border-1 w-full ' />
                  {/* below part */}
                  <div className='flex items-center  gap-5 text-xs font-medium text-left'>
                    <div>
                      <p>{ChangedSWDate}</p>
                      <p className='mt-1 border p-1 w-[167px] text-left bg-gray-300'>
                        {changeDateFormat(requestSW)}
                      </p>
                    </div>
                    <div>
                      <p>{ProposeNewSWDate}</p>
                      {!requestData?.request_type?.includes('Expedite')
                        ? (() => {
                          const minDate = requestData?.current_ship_week
                            ? getStartDate(
                              getPossibleShipWeek(
                                requestData?.current_ship_week
                              )
                            )
                            : getStartDate('')
                          const maxDate = getStartDate(
                            requestData?.requested_ship_week,
                            -1
                          )

                          return (
                            <DatePicker
                              showWeekNumbers
                              // filterDate={(date) =>
                              //   date.getDay() === 1 &&
                              //   date >= minDate &&
                              //   date <= maxDate
                              // }
                              filterDate={(date) =>
                                date.getDay() === 1
                              }
                              selected={date} // The selected date should match the SW typed
                              onChange={(selectedDate) => {
                                if (
                                  selectedDate >= minDate &&
                                  selectedDate <= maxDate
                                ) {
                                  handleChangeDate(selectedDate)
                                  setCheckValid(false)
                                }
                              }}
                              className='p-1 mt-1 border bg-yellow-300'
                              ref={datePickerRef}
                              calendarStartDay={1}
                              minDate={minDate}
                              maxDate={maxDate}
                              showYearDropdown
                              excludeDates={[
                                new Date(changeDateFormat(requestData?.requested_ship_week)),
                                new Date(changeDateFormat(requestData?.current_ship_week)), new Date()
                              ]}
                            />
                          )
                        })()
                        : (() => {
                          const minDate = getStartDate(
                            getPossibleShipWeek(
                              requestData?.requested_ship_week
                            )
                          )
                          const maxDate = requestData?.current_ship_week
                            ? getStartDate(requestData?.current_ship_week, -1)
                            : getStartDate('')

                          return (
                            <DatePicker
                              showWeekNumbers
                              // filterDate={(date) =>
                              //   date.getDay() === 1 &&
                              //   date >= minDate &&
                              //   date <= maxDate
                              // }
                              filterDate={(date) =>
                                date.getDay() === 1
                              }
                              selected={date} // The selected date should match the SW typed
                              onChange={(selectedDate) => {
                                if (
                                  selectedDate >= minDate &&
                                  selectedDate <= maxDate
                                ) {
                                  handleChangeDate(selectedDate)
                                  setCheckValid(false)
                                }
                              }}
                              className='p-1 mt-1 border bg-yellow-300'
                              ref={datePickerRef}
                              calendarStartDay={1}
                              minDate={minDate}
                              maxDate={maxDate}
                              showYearDropdown
                              excludeDates={[
                                new Date(changeDateFormat(requestData?.requested_ship_week)),
                                new Date(changeDateFormat(requestData?.current_ship_week)), new Date()
                              ]}
                            />
                          )
                        })()}
                    </div>
                  </div>
                  {checkValid && (
                    <p className='text-red-500 test-xs'>{EntervalidSW}</p>
                  )}

                </div>
              )}
              {checkPastDue && (
                <p className='text-red-500 test-xs'>
                  Selected CR Is In Past Due, Can't Accept.
                </p>
              )}
              <div className='w-full flex justify-center items-center gap-4 mt-4'>
                <button
                  className={`text-xs font-medium w-[80px] 
                  ${ (inputsw.length > 0 &&  !/^\d{1,2}\.\d{2}$/.test(inputsw)) ||checkValid ? 'cursor-not-allowed' : 'cursor-pointer'} rounded-md  h-[38px] text-white bg-green-600 `}
                  onClick={() => {

                    if ((inputsw.length > 0 && !/^\d{1,2}\.\d{2}$/.test(inputsw)) || checkValid) return
                    else if (checkvalidpastDue(requestData?.requested_ship_week) && !inputsw)
                      setCheckPastDue(true)
                    else openAcceptRequestModal()
                  }}
                // disabled={!checkValid}
                >
                  {Confirm}
                </button>

                <PopupModal
                  isOpen={showAcceptRequestModal}
                  message={'Are you sure you want to confirm?'}
                  onCancel={closeAcceptRequestModal}
                  cancelText={i18next.t('AcceptModal.Cancel')}
                  onConfirm={handleAcceptRequest}
                  confirmText={i18next.t('AcceptModal.Confirm')}
                />
                <button
                  className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-gray-500 '
                  onClick={() => {
                    setinputsw('')
                    setCheckValid('')
                    setShowAcceptModal(false)
                  }}
                >
                  {Cancel}
                </button>
              </div>
            </div>
          )}
        </div>
      </dialog>
      {/* <PopupBanner
        isOpen={showChangeRequestBanner}
        message={i18next.t('PopUpMessage.Changerequestissubmittedsucessfully')}
        onCancel={closePopUpModal}
      // text={poRow}
 
      /> */}

      {/*Reject Request Dialog Box */}

      <dialog
        open={showRejectModal}
        className='p-3 inset-0 fixed z-50 bg-black bg-opacity-50 w-full min-h-screen'
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center'>
          <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
            <div className='w-full flex justify-end items-center'>
              <button
                className='border-2 border-gray-300 p-2 rounded-md'
                onClick={() => {
                  setShowRejectModal(false)
                  setinputsw('')
                  setCheckValid('')
                }}
              >
                ❌
              </button>
            </div>
            <div className='w-full flex justify-center items-center mt-2 text-center'>
              <p className='text-sm font-medium'>{cancelReasonCode}</p>
            </div>
            {/* This is to show reasonCodeValue once user selected from list  */}
            <ReasonCodeView
              reasonCodeValue={reasonCodeValue}
              setreasonCodeValue={setreasonCodeValue}
            />
            {/* View End */}
            {/*  ReasonCode List Component to view all list and select*/}
            <ReasonCodeList
              reasonCodeValue={reasonCodeValue}
              setreasonCodeValue={setreasonCodeValue}
              type={
                requestData?.request_type !== 'Divert' ? 'common' : 'Divert'
              }
            />
            {/* End Component */}
            <div className='w-full flex justify-center items-center gap-4 mt-4'>
              <button
                className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-red-600 '
                onClick={openAcceptRequestModal}
              >
                {Reject}
              </button>
              <PopupModal
                isOpen={showAcceptRequestModal}
                message={'Are you sure you want to Reject?'}
                onCancel={closeAcceptRequestModal}
                cancelText={i18next.t('AcceptModal.Cancel')}
                onConfirm={handleRejectRequest}
                confirmText={i18next.t('AcceptModal.Confirm')}
              />
              <button
                className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-gray-500  '
                onClick={() => {
                  setShowRejectModal(false)
                  setreasonCodeValue('')
                  setinputsw('')
                  setCheckValid('')
                }}
              >
                {Cancel}
              </button>
            </div>
          </div>
        </div>
      </dialog>
    </div>
  )
}

export default ExpediteActionComp

export const checkvalidpastDue = (requested_ship_week) => {
  const [req_sw, req_sw_year] = requested_ship_week.split('.').map(Number)
  const presentShipWeek = getPresentShipWeek();
  const [presentWeek, presentYear] = presentShipWeek.split('.').map(Number);

  const check = req_sw_year < presentYear || (req_sw < presentWeek && req_sw_year === presentYear)
  return check
}
