import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import logo1 from "../image/logo_head1.svg";
import bgimage from "../image/bg-image.jpg";

const Login = ({ updateUser }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // State to manage loading during authentication

  const vendorUrl = `${process.env.REACT_APP_ANOTHER_API_URL}/auth/oracle?role=vendor`;
  const buyerUrl = `${process.env.REACT_APP_ANOTHER_API_URL}/auth/oracle?role=buyer`;

  /** Handles User Login */
  const handleLogin = async (email) => {
    const payload = { userid: email, password: "" };
    const token = Cookies.get("idcs_user_assertion");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/HFTUserLogin`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Role: Cookies.get("role"),
          },
        }
      );

      console.log("Login Response:", response?.data);
      localStorage?.setItem("userData", JSON.stringify(response?.data));
      updateUser(response?.data);

      switch (response?.data?.roleName) {
        case "SUPPLIER_ROLE":
          const url = sessionStorage.getItem("my_task_url");
          if (url) {
            sessionStorage?.removeItem("event_url");
            navigate(url);
          } else {
            navigate("/vendor");
          }
          break;

        case "BUYER_ROLE":
        case "MANAGER_ROLE":
        case "OVERSEAS_ROLE":
          response.data["roleName"] = "BUYER_ROLE";
          localStorage?.setItem("userData", JSON.stringify(response?.data));
          navigate("/buyer");
          break;

        case "ADMIN_ROLE":
          navigate("/admin");
          break;

        default:
          navigate("/login-error");
      }
    } catch (error) {
      console.error("Login Error:", error);
      navigate("/login-error");
    } finally {
      setIsLoading(false);
    }
  };

  /** Fetch User Information Post-SSO */
  const getUser = async () => {
    const role = Cookies.get("role");

    try {
      const result = await fetch(
        `${process.env.REACT_APP_ANOTHER_API_URL}/idcs?role=${role}`,
        { method: "GET", credentials: "include" }
      );

      const data = await result.json();
      console.log("User Token Sub:", data?.token?.sub);
      await handleLogin(data?.token?.sub);
    } catch (err) {
      console.error("User Fetch Error:", err);
      navigate("/login-error");
    }
  };

  /** Runs Once on Component Mount */
  useEffect(() => {
    if (Cookies.get("idcs_user_assertion")) {
      getUser();
    } else {
      setIsLoading(false); // If no token, show the login UI
    }
  }, []);

  /** Show Loading Screen During Authentication */
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-lg font-semibold">Authenticating... Please wait.</p>
      </div>
    );
  }

  /** Default Login Page UI */
  return (
    <div
      className="relative h-screen w-full bg-cover bg-center"
      style={{ backgroundImage: `url(${bgimage})` }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-40 backdrop-blur-lg"></div>
      <div className="relative flex items-center justify-center h-full">
        <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
          <div className="text-center mb-6">
            <img
              src={logo1}
              alt="Logo"
              className="object-contain w-[185px] h-[60px] mx-auto"
            />
            <p className="mt-4 text-gray-700">
              Connecting vendors and buyers to track orders, approvals, and
              manage change requests efficiently.
            </p>
          </div>

          <p className="text-center mb-4">Choose the login option:</p>
          <a
            href={vendorUrl}
            className="block text-center bg-red-600 text-white py-2 rounded-md mb-2"
          >
            Login as Vendor
          </a>
          <a
            href={buyerUrl}
            className="block text-center bg-red-600 text-white py-2 rounded-md"
          >
            Login as HFT Employee
          </a>

          <div className="text-center mt-6">
            <span className="text-gray-600">Powered by:</span>
            <img
              src="https://images.harborfreight.com/media/logos/harborfreight-logo.svg"
              alt="Harbor Freight Logo"
              className="w-[130px] h-[20px] mx-auto mt-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
