import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ValidateAPI } from '../../utils/Utils'
import { useNavigate } from 'react-router-dom'
import { CommonAPICall } from '../../CommonAPiContext/context'
import { useTranslation } from 'react-i18next'
const VendorTotalPO = ({ vendor }) => {
    const user = JSON.parse(localStorage?.getItem('userData'))
    const navigate = useNavigate();
  const [poCount, setPoCount] = useState(null)
  const { addFilterData } = CommonAPICall()
  const{t} = useTranslation()
  const{TotalNoofOpenPOs} = t('vendortotalpopage')
  const fetchPoCount = () => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}:8088/api/admin/get-total-po-counts-by-vendor?vendorNumber=${user?.supplierId}`;
    axios
      .get(URL, ValidateAPI())
      .then((response) => {
        console.log('PO count data: ', response?.data);  // Logs the full response
        setPoCount(response?.data?.TOTAL_PO);  // Accessing the TOTAL_PO field for the number
      })
      .catch((error) => {
        console.error('Error fetching PO count:', error);
      });
  };
  

  useEffect(() => {
    fetchPoCount()
  }, [vendor])

  const handleClick = () => {
    if(poCount !== null)
    {
      addFilterData({
        hftUserId: user?.supplierId,
        tableName: 'MyPos',
        clFilter: []
      })
      setTimeout(() => {
        navigate('/vendor/open_pos')
      }, 500);
    }
    
}

  return (
    <div className="flex items-center space-x-4 border border-gray-300 p-2 rounded-md w-1/4">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="50" height="50" fill="none">
        <rect x="10" y="10" width="80" height="80" rx="5" fill="#FFA652" />
        <path d="M80 10 H70 A10 10 0 0 1 80 20 V10 Z" fill="#FFFFFF" />
        <rect x="20" y="30" width="60" height="5" fill="#FFFFFF" />
        <rect x="20" y="40" width="50" height="5" fill="#FFFFFF" />
        <rect x="20" y="50" width="60" height="5" fill="#FFFFFF" />
        <rect x="20" y="70" width="20" height="5" fill="#FFFFFF" />
        <rect x="45" y="70" width="35" height="5" fill="#FFFFFF" />
      </svg>

      <p className='font-medium'>{TotalNoofOpenPOs}</p>

      <div className="bg-blue-400 text-white  px-4 py-2 rounded underline cursor-pointer"
      onClick={handleClick}
      >
        {poCount !== null ? poCount : 'No Data Found'}
      </div>
    </div>
  )
}

export default VendorTotalPO
