import React, { useEffect, useTransition } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const RefreshLogin = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {t} = useTranslation()
  const {Yoursessioncookiesseemstobeinvalid,Attemptingtorefreshlogin} = t("")
  const { role } = location.state || {}

  useEffect(() => {
    if (role) {
      const loginUrl = `${process.env.REACT_APP_ANOTHER_API_URL}/auth/oracle?role=${role}`
      setTimeout(() => {
        window.location.href = loginUrl
      }, 1000) // Display the message for 1 seconds
    } else {
      navigate('/session-expired')
    }
  }, [role, navigate])

  return (
    <div>
      <h1>{Yoursessioncookiesseemstobeinvalid}</h1>
      <p>{Attemptingtorefreshlogin}</p>
    </div>
  )
}

export default RefreshLogin
