import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { CustomTooltip } from '../../screens/buyer/Dashboard'
import { colors } from '../buyer/PieChartComp'
import Loading from '../Loading'
import i18next from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ValidateAPI } from '../../utils/Utils'
import { CommonAPICall } from '../../CommonAPiContext/context'
const VendorPieChart = ({vendor,cpiOffice}) => {
  const user = JSON.parse(localStorage?.getItem('userData'))
  const [reqStatusData, setReqStatusData] = useState([])
  const [pieload, setPieload] = useState(false)
  const fetchReqStatusData = () => {
    setPieload(true)
    const  overseasURL =`${process.env.REACT_APP_API_DOMAIN}:8088/api/admin/get-all-counts-cr-status-vendor-filter-cpi-office?cpiOffice=${cpiOffice}&vendorNumber=${vendor}`
    const vendorURL =`${process.env.REACT_APP_API_DOMAIN}:8088/api/admin/get-all-counts-cr-status-vendor-filter?vendorNumber=${user?.supplierId}`
    const URL = user?.userGroupName==="OVERSEAS_ROLE" ? overseasURL : vendorURL
    axios
      .get(
        URL,ValidateAPI()
      )
      .then((response) => {
        console.log('Data from reqtype: ', response?.data)
        const dataArray = Object.entries(response?.data)
          .filter(([key]) => key !== 'TOTAL_CR' && key !== 'OPEN')
          .map(([status, value]) => ({ status, value }))

        console.log(dataArray)
        setReqStatusData(dataArray)
        setPieload(false)
      })
      .catch((error) => {
        if(error.response.status===401 || error.response.status===403){
          navigate('/login-error')
        }
        setPieload(false)
      })
  }
  const { t } = useTranslation()
  const { DistributionbyRequestStatus, Norelateddatafound } = t('pieChart')
  const { addFilterData } = CommonAPICall()
  const navigate = useNavigate()
  const handleBarClick = (entry) => {
    let status = entry?.payload?.status
    if(user?.userGroupName==="OVERSEAS_ROLE")
    return 
    if (status?.includes('CLOSED')) {
      addFilterData({
        hftUserId: user?.supplierId,
        tableName: 'VendorChangeReqTable',
        clFilter: []
      })
      setTimeout(() => {
        addFilter('status',status)
      }, 100);
    } else {
      status = status === 'AWAITING_RESPONSE' ? 'AWAITING RESPONSE' : status
      addFilterData({
        hftUserId: user?.supplierId,
        tableName: 'VendorChangeReqTable',
        clFilter: []
      })
     setTimeout(() => {
      addFilter('supplier_response',status)
     }, 100);
    }
  }
  function addFilter(id,status){
    addFilterData({
      hftUserId: user?.supplierId,
      tableName: 'VendorChangeReqTable',
      clFilter: [
        {
          column: id,
          values: [status]
        },
      ]
    })
     navigate(`/vendor/po_change_requests`)
  }
  useEffect(() => {
    fetchReqStatusData()
  }, [vendor])
  return (
    <div className="w-[50%] flex justify-center items-center flex-col  sm:w-[100%]
    gap-4 shadow-lg rounded-md border border-gray-300 p-3 max-h-[360px]">
      <div className="w-full flex justify-start items-center flex-col ">
        <p className="text-sm font-medium">{DistributionbyRequestStatus}</p>
        <div className="relative flex justify-between items-center w-full">
          {!pieload ? (
            reqStatusData ? (
              <div className='flex flex-col gap-2 w-full justify-start items-center'>
                <ResponsiveContainer width='90%' height={180}>
                  <PieChart>
                    <Tooltip content={<CustomTooltip />} />
                    <Pie
                      data={reqStatusData}
                      innerRadius={55}
                      outerRadius={70}
                      paddingAngle={5}
                      dataKey='value'
                      onClick={(e) => handleBarClick(e)}
                    >
                      {reqStatusData?.map((entry, index) => (
                        <Cell
                          key={`cell-${entry?.status}`}
                          fill={colors[index]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                <div className='w-full flex gap-5 justify-center items-center flex-wrap'>
                  {reqStatusData?.map((item, index) => (
                    <div className='flex gap-2 items-center'>
                      <div
                        className='w-[10px] h-[10px]'
                        style={{ backgroundColor: colors[index] }}
                      />
                      <p className='text-xs'>{item?.status}</p>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <p>{Norelateddatafound}</p>
            )
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
  )
}

export default VendorPieChart
