import React, { useEffect, useState, useRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
   ValidateAPI,
  changeDateFormat,
  getDateofMonday,
  getPossibleShipWeek,
  getPresentShipWeek,
  getStartDate,
  makeSWformat,
  reasonCodesEN,
  reasonCodesMN,
  updateActionHistory,
  validateShipWeekInput,
  validateShipWeekInput1
} from '../../utils/Utils'
import { getWeek } from '../../screens/buyer/NewPoList'
import axios from 'axios'
import { weeksInBetween } from '../../screens/vendor/ConfirmPo'
import Loading from '../Loading'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import PopupModal from '../PopupModal'
import ReasonCodeList, { ReasonCodeView } from '../ReasonCodeList'
import PopUpPastDue from '../PopUpPastDue'
const ReconfirmActionComp = ({
  requestData,
  setShowChangeRequestBanner,
  setMessageForBanner
}) => {
  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [showRequestChangeModal, setShowRequestChangeModal] = useState(false)
  const [showAcceptDatePicker, setShowAcceptDatePicker] = useState(false)
  const [reasonCodeValue, setreasonCodeValue] = useState('')
  const [secondReason, setSecondReason] = useState('')
  const [requestSW, setRequestSW] = useState('')
  const [requestDC, setRequestDC] = useState('')
  const [comment, setComment] = useState('')
  const [showConfirmActionModal, setShowConfirmActionModal] = useState(false)
  const [showpastDueModel, setShowPastDueModel] = useState(false)
  const [showPopUpForPast, setShowForPast] = useState(false)
  // const dc_list = ["CAM", "DAL", "DIL", "MOR", "CHI"];
  const [dc_list, setDCList] = useState(
    JSON.parse(sessionStorage?.getItem('dcList'))
  )
  const [date, setDate] = useState(new Date())
  const selectedRowArray = [requestData]
  const user = JSON.parse(localStorage.getItem('userData'))
  const [submitting, setSubmitting] = useState(false)
  const [reason_codes, setReasonCode] = useState([])
  // const [showChangeRequestBanner, setShowChangeRequestBanner] = useState(false)
  const [showConfirmOrdersBanner, setshowConfirmOrdersBanner] = useState(false)
  const [editCrIdResponse, setEditCrIdResponse] = useState('')
  const [editPoRow, setEditPORow] = useState('')
  const [poRow, setPoRow] = useState('')
  const [crIdResponse, setCrIdResponse] = useState('')
  const [reloadPage, setReloadPage] = useState(false)
  const [inputsw, setinputsw] = useState('')
  const { t } = useTranslation()
  const datePickerRef = useRef(null)
  const inputRef = useRef(null)
  const [validSw, setValidSw] = useState(false)
  const [validationMessage, setValidationMessage] = useState('')

  const {
    Change,EntervalidSW,SelectNewSWDate,CURSWDATE,SelectNewSW,CURSW,
    SelectDC,
    SelectedSW,
    Commentload,
    PleaseselectReasoncodefortheselectedorders,
    Changetype,
    Selectatype,
    Shipweek,
    DC,
    Price,
    Quantity,
    ReasonCode,
    Selectareason,
    RequestChange,
    Cancel
  } = t('ConfirmActionComp')

  const closeConfirmActionModal = () => {
    setShowConfirmActionModal(false)
  }

  
  const openConfirmActionModal = () => {
    const [req_sw, req_sw_year] = requestData?.current_ship_week.split('.').map(Number)
    const presentShipWeek = getPresentShipWeek();
    const [presentWeek, presentYear] = presentShipWeek.split('.').map(Number);

    if (req_sw_year < presentYear || (req_sw < presentWeek && req_sw_year === presentYear))
      setShowPastDueModel(true)
    else
    setShowConfirmActionModal(true)
  }
  const openEditSW =()=>{
    setShowPastDueModel(false)
    setShowRequestChangeModal(true)
    setSecondReason('sw')
  }

  const handleConfirmPos = () => {
    const request_payload = []
    selectedRowArray?.map((row) => {
      const payload = {
        id_open_request: row?.id_open_request,
        vendorNumber: Number(row?.vendor_number),
        vendorName: row?.vendor_name,
        createdBy: row?.inventory_analyst,
        current_shipweek: row?.current_ship_week,
        original_ship_week: row?.original_ship_week
      }
      request_payload?.push(payload)
    })
    console.log('ReConfirm Po payload: ', request_payload)
    setSubmitting(true)

    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-reconfirm-po`,
        request_payload,
         ValidateAPI()
      )
      .then((response) => {
        console.log('response from confirm po: ', response?.data)
        if (response?.data) {
          const crIdResponse = response.data.map((item) => item.id_open_request)
          const poRow = response.data.map((item) => item.po_number)

          console.log('crIdResponse:', crIdResponse)
          console.log('poNumber:', poRow)

          setCrIdResponse(crIdResponse)
          setPoRow(poRow)
        }

        setShowChangeRequestBanner(true)
        setMessageForBanner(i18next.t('VendorPopUp.ChangeRequestSubmittedSuccessfully'))
        setSubmitting(false)
        updateActionHistory('Accepted', selectedRowArray)
        updateActionHistory('Closed', selectedRowArray)
        // window.location?.reload();
        // alert(i18next.t("VendorPopUp.Ordersconfirmedsuccessfully"));
      })
      .catch((error) => {
        console.log('error while confirming PO: ', error)
        setSubmitting(false)
      })
    closeConfirmActionModal()
  }

  const rejectPop = () => {
    const rejectPosubmit = window.confirm(
      i18next.t('VendorPopUp.Doyouwanttorequestchangesintheseorders')
    )
    if (rejectPosubmit) {
      setShowRequestChangeModal(true)
    }
  }

  const editConfirm = () => {
    const req_payload = []
    if (reasonCodeValue?.length > 0) {
      if (requestSW?.length > 0 || requestDC?.length > 0) {
        selectedRowArray?.map((item) => {
          const req_obj = {
            hft_cr_id: item?.id_open_request,
            requested_dc: requestDC,
            reason_code_dc: secondReason === 'dc' ? reasonCodeValue : '',
            requested_ship_week: item?.requested_ship_week ?? '',
            reason_code_ship_week: secondReason === 'sw' ? reasonCodeValue : '',
            supplier_response: 'ACCEPTED',
            current_ship_week: requestSW,
            status: 'OPEN',
            followups: 1,
            vendor_selected_ship_week: requestSW,
            original_ship_week: item?.original_ship_week,
            ship_week_delay: Math.abs(
              weeksInBetween(requestSW ?? '', item?.on_time_target)
            ),
            vendorName: item?.vendor_name,
            createdBy: item?.inventory_analyst,
            requestType: 'ReConfirm'
          }
          return req_payload?.push(req_obj)
        })
        console.log('payload before edit reconfirm: ', req_payload)
        setSubmitting(true)
        axios
          .put(
            `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-edit-confirm-po`,
            req_payload,
             ValidateAPI()
          )
          .then((response) => {
            console.log('response from editconfirm: ', response?.data)
            setRequestDC('')
            setRequestSW('')
            setSubmitting(false)
            setShowRequestChangeModal(false)
            setSecondReason('')
            setreasonCodeValue('')
            updateActionHistory('Accepted', selectedRowArray)
            if (response?.data) {
              const crIdResponse = response.data.map(
                (item) => item.id_open_request
              )
              const poRow = response.data.map((item) => item.po_number)

              console.log('crIdResponse:', crIdResponse)
              console.log('poNumber:', poRow)

              setCrIdResponse(crIdResponse)
              setPoRow(poRow)
            }

            setShowChangeRequestBanner(true)
            setMessageForBanner(i18next.t('VendorPopUp.ChangeRequestSubmittedSuccessfully'))
            // alert(i18next.t("VendorPopUp.changesmadesuccessfully"));
            // window.location.reload();
          })
          .catch((error) => {
            console.log('error while submitting edit confirm: ', error)
            setSubmitting(false)
          })
      } else {
        alert(i18next.t('VendorPopUp.Pleaseaddnewvaluebeforesubmitting'))
      }
    } else {
      alert(
        i18next.t(
          'VendorPopUp.PleaseselectReasonCodeChangeTypebeforesubmitting'
        )
      )
    }
  }

  const handleRejectPos = () => {
    const request_payload = []
    const comments_payload = []
    if (!reasonCodeValue?.length > 0)
      return alert(i18next.t('VendorPopUp.Pleaseselectareasoncode'))
    if (!comment)
      return alert(i18next.t('VendorPopUp.Pleaseaddcommentbeforesubmitting'))
    selectedRowArray?.map((row) => {
      const payload = {
        id_open_request: row?.id_open_request,
        vendorNumber: Number(row?.vendor_number),
        reasonCodeShipWeek: reasonCodeValue,
        vendorName: row?.vendor_name,
        createdBy: row?.inventory_analyst,
        original_ship_week: ''
      }
      const comment_indi = {
        hftcrid: row?.id_open_request,
        po_number: row?.po_number,
        added_by_role_name: user?.roleName?.split('_')?.[0],
        added_by_name: user?.fullName,
        comment: comment,
        type: 'Comment',
        SKU: row?.sku
      }
      request_payload?.push(payload)
      comments_payload?.push(comment_indi)
    })

    console.log('Reject Po payload: ', request_payload)
    console.log('Reject comments payload: ', comments_payload)
    setSubmitting(true)
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-confirm-reject-po`,
        request_payload,
         ValidateAPI()
      )
      .then((response) => {
        console.log('response from reject po: ', response?.data)
        if (response?.data) {
          const crIdResponse = response.data.map((item) => item.id_open_request)
          const poRow = response.data.map((item) => item.po_number)

          console.log('crIdResponse:', crIdResponse)
          console.log('poNumber:', poRow)

          setCrIdResponse(crIdResponse)
          setPoRow(poRow)
        }

        setShowChangeRequestBanner(true)
        setMessageForBanner(i18next.t(' VendorPopUp.ChangeRequestRejectedSuccessfully '))
        setShowRequestChangeModal(false)
        setreasonCodeValue('')
        setComment('')

        axios
          .post(
            `${process.env.REACT_APP_API_DOMAIN}:8088/api/create-cr-activity`,
            comments_payload,
             ValidateAPI()
          )
          .then((response) => {
            console.log('response from submitting comment: ', response?.data)
            window.location?.reload()
            setSubmitting(false)
            alert(i18next.t('VendorPopUp.Orderschangedsuccessfully'))
          })
          .catch((error) => {
            console.log('error from comments submission: ', error)
            setSubmitting(false)
          })
      })
      .catch((error) => {
        console.log('error while reject PO: ', error)
      })
  }



  const renderBlock = (reason) => {
    if (reason === 'dc') {
      return (
        <select
          className='w-full border border-gray-300 p-2 rounded-md text-xs'
          onChange={(e) => setRequestDC(e?.target?.value)}
        >
          <option disabled>{SelectDC}</option>
          {dc_list?.map((data, index) => (
            <option value={data?.DC_NAME} key={data + index}>
              {data?.DC_NAME}
            </option>
          ))}
        </select>
      )
    } else if (reason === 'sw') {
      return (
        <div className='w-full flex flex-col gap-4 items-center text-left'>
          {/* Upper Section */}
          <div className='flex items-start gap-5 text-xs font-medium w-full'>
            {/* CUR SW */}
            <div className='w-1/2'>
              <p>{CURSW}</p>
              <p className='mt-1 border p-1 w-full text-left bg-gray-300'>
                {selectedRowArray[0].current_ship_week}
              </p>
            </div>
            {/* Select New SW */}
            <div className='w-1/2'>
              <p>{SelectNewSW}</p>
              <input
              ref={inputRef}
                type='number'
                className='border p-1 mt-1 text-xs w-full bg-yellow-300 placeholder-black'
                placeholder='SW.YY(e.g.,00.00)'
                value={inputsw}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9.]/g, '')
                  setinputsw(value)
                  const validation = validateShipWeekInput1(
                    value,
                    selectedRowArray[0].current_ship_week
                  )

                  if (!validation.isValid) {
                    setValidSw(true)
                    setValidationMessage(validation.message)
                  } else {
                    setValidSw(false)
                    setValidationMessage('')
                    const sw = getPossibleShipWeek(value)
                    setRequestSW(makeSWformat(sw))
                    setDate(getDateofMonday(sw))
                    datePickerRef.current.setFocus()
                  }
                  // if (value.length === 5) {
                  
                  // } else {
                  //   setValidSw(false) // Reset message if input is incomplete
                  //   setValidationMessage('')
                  // }
                }}
              />
            </div>
          </div>

          {/* Divider */}
          <div className='h-px my-2 bg-gray-500 w-full' />

          {/* Lower Section */}
          <div className='flex items-start gap-5 text-xs font-medium w-full'>
            {/* CUR SW DATE */}
            <div className='w-1/2'>
              <p>{CURSWDATE}</p>
              <p className='mt-1 border p-1 w-full text-left bg-gray-300'>
                {changeDateFormat(selectedRowArray[0].current_ship_week)}
              </p>
            </div>
            {/* Select New SW Date */}
            <div className='w-1/2'>
              <p>{SelectNewSWDate}</p>
              <DatePicker
                showWeekNumbers
                filterDate={(date) => date.getDay() === 1}
                selected={date}
                ref={datePickerRef}
                onChange={(date) => handleChangeDate(date)}
                className='p-1 mt-1 px-2 border text-xs w-full bg-yellow-300'
                calendarStartDay={1}
                showYearDropdown
                minDate={getStartDate(getPossibleShipWeek(selectedRowArray[0].current_ship_week))}
                excludeDates={[
                  new Date(
                    changeDateFormat(selectedRowArray[0].current_ship_week)
                  )
                ]}
              />
            </div>
          </div>

          {/* Validation Message */}
          {validSw && <p className='text-red-500 text-xs'>{EntervalidSW}</p>}
        </div>
      )
    } else {
      return (
        <div className='w-full flex flex-col gap-2'>
          <label htmlFor='sr_comment' className='text-xs font-medium'>
            {Commentload}
          </label>
          <textarea
            id='sr_comment'
            className='w-full border border-gray-300 p-2 rounded-md text-xs'
            placeholder={t('ConfirmActionComp.Typehereindetail')}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
      )
    }
  }

  const handleChangeDate = (date) => {
    setDate(date)
    setRequestSW(getWeek(date))
    setinputsw(getWeek(date))
    setValidSw(false)
  }
useEffect(()=>{
  setDate(getDateofMonday(getPossibleShipWeek(selectedRowArray[0].current_ship_week)))
},[])

useEffect(() => {
  if (renderBlock && inputRef.current) {
    inputRef.current.focus()
  }
},[renderBlock])
  return (
    <div className='w-full flx flex-col gap-2 justify-center items-center'>
      <div className='flex gap-6 justify-center items-center'>
      <div className="h-6 border-l  border-black"></div>
        <button
          className='mr-2'
          onClick={openConfirmActionModal}
          title='Accept'
        >
          <svg
            className='w-6 h-6 text-green-600'
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='currentColor'
            viewBox='0 0 20 20'
          >
            <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z' />
          </svg>
        </button>
        <PopupModal
          isOpen={showConfirmActionModal}
          message={i18next.t('VendorPopUp.Doyouwanttoconfirtheseorders')}
          onCancel={closeConfirmActionModal}
          cancelText={i18next.t('AcceptModal.Cancel')}
          onConfirm={handleConfirmPos}
          confirmText={i18next.t('AcceptModal.Confirm')}
        />
         <PopUpPastDue
          isOpen={showpastDueModel}
          message={'Selected CR Is In Past Due, Click Confirm, To Change SW and Accept'}
          onCancel={() => setShowPastDueModel(false)}
          cancelText={i18next.t('AcceptModal.Cancel')}
          onConfirm={openEditSW}
          confirmText={i18next.t('AcceptModal.Confirm')}
        />
        <div className="h-6 border-l border-black"></div>
        <button
          onClick={() => {setShowRequestChangeModal(true);setShowForPast(true)}}
          title='ChangeType'
        >
          <svg
            className='w-6 h-6 text-white bg-black p-1 border border-slate-900 rounded-full font-bold'
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
          >
            <path
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='3'
              d='M13.5 9.2 15 7h5m0 0-3-3m3 3-3 3M4 17h4l1.6-2.3M4 7h4l7 10h5m0 0-3 3m3-3-3-3'
            />
          </svg>
        </button>
        <div className="h-6 border-l  border-black"></div>
      </div>

      {/*Request Change Dialog Box */}
      <dialog
        open={showRequestChangeModal}
        className='p-3 inset-0 fixed z-40 bg-black bg-opacity-50 w-full min-h-screen'
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center'>
          {submitting ? (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <Loading type='Submitting...' />
            </div>
          ) : (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <p className='w-full text-center'>
                {PleaseselectReasoncodefortheselectedorders}
              </p>
              {/* This is to show reasonCodeValue once user selected from list  */}
              <ReasonCodeView
                reasonCodeValue={reasonCodeValue}
                setreasonCodeValue={setreasonCodeValue}
              />
              {/* View End */}
            {showPopUpForPast &&
              <div className='w-full flex flex-col gap-2'>
              <label
                htmlFor='change_type_dd'
                className=' text-left text-xs font-medium'
              >
                {Changetype}
              </label>
              <select
                onChange={(e) => setSecondReason(e?.target?.value)}
                id='change_type_dd'
                className='p-2 rounded-md outline-none border border-gray-300 text-xs w-full font-medium'
                value={secondReason}
              >
                <option value='' disabled>
                  {Selectatype}
                </option>
                {/* <option value='dc' disabled>{DC}</option> */}
                <option value='sw'>{Shipweek}</option>
                <option value='price' disabled>
                  {Price}
                </option>
                <option value='qty' disabled>
                  {Quantity}
                </option>
              </select>
            </div>
            }
              {/*  ReasonCode List Component to view all list and select*/}
              {secondReason?.length > 0 && (
                <ReasonCodeList
                  reasonCodeValue={reasonCodeValue}
                  setreasonCodeValue={setreasonCodeValue}
                />
              )}
              {/* End Component */}
              <div className='w-full'>
                {secondReason?.length > 0 &&
                  reasonCodeValue?.length > 0 &&
                  renderBlock(secondReason)}
              </div>
              <div className='w-full flex justify-center items-center gap-2 mt-4'>
                {secondReason &&
                  (secondReason === 'dc' || secondReason === 'sw' ? (
                    <button
                      className={`text-xs font-medium px-2 rounded-md ${validSw || requestSW?.length === 0 ? 'cursor-not-allowed' : 'cursor-pointer'} h-[38px] text-white bg-[#ff9500] `}
                      onClick={editConfirm}
                      disabled={validSw || requestSW?.length === 0}
                    >
                      {/* {RequestChange} */}{Change}
                    </button>
                  ) : (
                    <button
                      className={`text-xs font-medium px-2 rounded-md  h-[38px] border bg-[#ff9500] text-white `}
                      onClick={handleRejectPos}
                      // disabled={validSw || requestSW?.length === 0}
                    >
                      {/* {RequestChange} */}{Change}
                    </button>
                  ))}
                <button
                  className='text-xs font-medium px-2 rounded-md h-[38px] text-white bg-gray-500'
                  onClick={() => {
                    setShowRequestChangeModal(false)
                    setreasonCodeValue('')
                    setRequestSW('')
                    setSecondReason('')
                    setinputsw('')
                    setValidSw('')
                    setShowForPast(false)
                  }}
                >
                  {Cancel}
                </button>
              </div>
            </div>
          )}
        </div>
      </dialog>
    </div>
  )
}

export default ReconfirmActionComp
