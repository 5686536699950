import React, { useEffect, useState } from 'react'
import Loading from '../Loading'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ValidateAPI } from '../../utils/Utils'
import { filter } from 'lodash'
import { CommonAPICall } from '../../CommonAPiContext/context'

const VendorTableView = ({ vendor, cpiOffice,vendorName }) => {
  const user = JSON.parse(localStorage?.getItem('userData'))
  const navigate = useNavigate()
  const [reqTypeData, setReqTypeData] = useState([])
  const [bigbarload, setBigBarLoad] = useState(false)
  const [selectedBuyer, setSelectedBuyer] = useState('')
  const [selectedVendor, setSelectedVendor] = useState('')
  const [selectSku, setSelectSku] = useState('')
  const { t } = useTranslation()
  const { VendorTable, RequestType, Count, Norelateddatafound } = t(
    'VendortableViewPage'
  )
  const { addFilterData } = CommonAPICall()
  const fetchReqTypeData = () => {
    setBigBarLoad(true)
    const overseasURL = `${process.env.REACT_APP_API_DOMAIN}:8088/api/admin/get-counts-email-table-cpi-office?cpiOffice=${cpiOffice}&vendorNumber=${vendor}`
    const vendorURL = `${process.env.REACT_APP_API_DOMAIN}:8088/api/admin/get-counts-email-table?vendorNumber=${user?.supplierId}`
    const URL = user?.userGroupName === "OVERSEAS_ROLE" ? overseasURL : vendorURL
    axios
      .get(
        URL, ValidateAPI()
      )
      .then((response) => {
        console.log(response)
        setReqTypeData(response?.data)
        setBigBarLoad(false)
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
        setBigBarLoad(false)
      })
  }

  useEffect(() => {
    fetchReqTypeData()
  }, [vendor])

  const handleHeaderClick = (requestType,entry) => {
    const typeUrlMap = {
      CONFIRM: '/vendor/po_confirm',
      RECONFIRM: '/vendor/po_reconfirm',
      DEFER: '/vendor/defer-request', //  for 'defer'
      DIVERT: '/vendor/divert-request', // for 'divert'
      EXPEDITE: '/vendor/expedite-request', //  correct term
      URGENT: '/vendor/urgent_orders'
    }

      // navigation
    const newPath = typeUrlMap[requestType]
    // console.log(newPath)
    console.log("entry",entry)
    const filterData = entry?.REQUEST_TYPE
    console.log("filertData", filterData)
    if (user?.userGroupName === "OVERSEAS_ROLE" ){
      addFilterData({
        hftUserId: user?.hftOracleId,
        tableName: 'NewPoList',
        clFilter: []
      })
      setTimeout(() => {
        addFilterData({
          hftUserId: user?.hftOracleId,
          tableName: 'NewPoList',
          buyerName:{},
          vendorName:vendor?{"id": vendor,"value": vendorName}:{},
          sku:{},
          clFilter: [
            {
              column: 'REQUEST_TYPE',
              values: [filterData]
            },
            {
              column: 'SUPPLIER_RESPONSE',
              values: ['Awaiting Response']
            }

          ]
        })
        navigate('/buyer/po_list',  {state: []})
      }, 100);
      return
    }
    
    else if (newPath && user?.userGroupName !== "OVERSEAS_ROLE") {
      navigate(newPath, { state: 'dashboard' })
    } else {
      console.error('Unhandled request type: ', requestType)
    }
  }
  

  return (
    <div className="w-full overflow-x-auto flex justify-center items-center flex-col gap-4 shadow-lg rounded-md border border-gray-300 p-3 max-h-[360px]">
      <p className="text-mds font-medium">{VendorTable}</p>
      <div className="relative flex justify-between items-center w-full">
        {!bigbarload ? (
          reqTypeData?.length > 0 ? (
            <table className="w-full border-collapse border text-center">
              <thead>
                <tr className="text-md font-medium">
                  <td className="border header text-md font-medium"style={{ minWidth: '150px', maxWidth: '200px' }}>REQUEST TYPE</td>
                  {reqTypeData
                  .filter((data) => data.REQUEST_TYPE.toUpperCase() !== "DELAY") 
                  .map((data) => (

                    <td key={data.requestType} className="border header border-gray-300"style={{ minWidth: '150px', maxWidth: '200px' }}>
                      {data.REQUEST_TYPE.toUpperCase()}
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr className="text-md font-medium">
                  <td className="border ">COUNT</td>
                  {reqTypeData
                  .filter((item) => item.REQUEST_TYPE.toUpperCase() !== "DELAY")
                  .map((item) => (
                    <td
                      key={item.REQUEST_TYPE + '_count'}
                      className="border cursor-pointer underline text-blue-500 border-gray-300"
                      onClick={() => handleHeaderClick(item.REQUEST_TYPE,item)}
                    >
                      {item.TOTAL_COUNT}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          ) : (
            <p>{Norelateddatafound}</p>
          )
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default VendorTableView;

