import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import {   ValidateAPI, getallDcList } from '../utils/Utils'
import { useTranslation } from 'react-i18next'

export default function UrgentOrderSku({
  vendorName,
  formDataList,
  setFormDataList
}) {
  const [date, setDate] = useState(new Date())
  const [skuList, setSkuList] = useState([])
  const [dcList, setDCList] = useState(
    JSON.parse(sessionStorage.getItem('dcList'))
  )
  const [UPCList, setupcList] = useState([])
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('userData')))
  const {t} = useTranslation()
  const{Message,ExtendedPrice,Quantity,UnitPrice,SkuDescription} = t("UrgentOrderbuyerComapPage")
  const addForm = () => {
    setFormDataList([
      ...formDataList,
      {
        supplierName: vendorName?.vendorName,
        supplierNo: vendorName?.vendorOracleId,
        createdBy: user?.fullName,
        hFTUserId: user?.hftOracleId,
        sku: '',
        quantity: '',
        unitPrice: '',
        dcValue: '',
        shipweek: '',
        message: '',
        extendedPrice: '',
        skuDescription: '',
        uPC: ''
      }
    ])
  }

  const clearForm = () => {
    setFormDataList([])
  }

  const handleChange = (index, name, value) => {
    const updatedFormDataList = [...formDataList]
    updatedFormDataList[index] = {
      ...updatedFormDataList[index],
      [name]: name === 'shipweek' ? getWeek(value) : value
    }
    setFormDataList(updatedFormDataList)
    // if(name==="sku"){
    //     GETUPCList(value);
    // }
  }
  const handleSku = (index, name, value) => {
    const skuObject = JSON.parse(value)
    const updatedFormDataList = [...formDataList]

    updatedFormDataList[index] = {
      ...updatedFormDataList[index],
      [name]: skuObject.skuNo
    }
    console.log(updatedFormDataList)
    updatedFormDataList[index] = {
      ...updatedFormDataList[index],
      skuDescription: skuObject.skuNmae
    }
    setFormDataList(updatedFormDataList)
    GETUPCList(skuObject.skuNo)
  }
  const handleUPC = (index, name, value) => {
    const UPCObject = JSON.parse(value)
    const updatedFormDataList = [...formDataList]

    updatedFormDataList[index] = {
      ...updatedFormDataList[index],
      [name]: UPCObject.upcno
    }
    updatedFormDataList[index] = {
      ...updatedFormDataList[index],
      unitPrice: UPCObject.listPrice
    }
    setFormDataList(updatedFormDataList)
  }
  const getWeek = (value) => {
    const currentDate = new Date(value)
    const currentYear = currentDate.getFullYear()
    const firstDayOfYear = new Date(currentYear, 0, 1)
    const days = Math.round(
      (currentDate - firstDayOfYear) / (24 * 60 * 60 * 1000)
    )
    const weekNumber = Math.ceil((days + firstDayOfYear.getDay() + 1) / 7)
    return `${weekNumber}.${currentYear % 100}`
  }

  const removeForm = (key) => {
    const updatedFormDataList = [...formDataList]
    const tempData2 = formDataList.filter((data, index) => {
      return index !== key
    })
    setFormDataList(tempData2)
  }

  useEffect(() => {
    GETSKUList()
    // GetDCList();
  }, [vendorName?.vendorOracleId, formDataList?.sku])

  const GETSKUList = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/po/skuListBySupplier?suppliernumber=${vendorName?.vendorOracleId}`,  ValidateAPI()
      )
      .then((response) => {
        // console.log(response?.data);
        setSkuList(response?.data)
      })
      .catch((error) => {
        alert(error?.response?.data)
      })
  }
  const GetDCList = () => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}:8088/api/get-dc-list`)
      .then((response) => {
        // console.log(response?.data);
        setDCList(response?.data)
      })
      .catch((error) => {
        alert(error?.response?.data)
      })
  }
  const GETUPCList = (value) => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/po/UPCListBySKU?skuNumber=${value}`,  ValidateAPI()
      )
      .then((response) => {
        // console.log(response?.data);
        setupcList(response?.data)
      })
      .catch((error) => {
        alert(error?.response?.data)
      })
  }
  return (
    <div>
      {formDataList.map((formData, index) => (
        <form
          onSubmit={(e) => {
            e.preventDefault()
            addForm()
          }}
          className='flex gap-2  items-center flex-wrap'
        >
          {/* SKU Selection*/}
          <div className='w-auto  '>
            <select
              name='sku'
              class='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 w-full'
              onChange={(e) => handleSku(index, 'sku', e.target.value)}
            >
              <option selected>SKU</option>

              {skuList?.map((data) => {
                return (
                  <option value={JSON.stringify(data)} className=''>
                    {/* <span className='px-3'>{selectedSku?.find((item) => item === data?.skuNo) ? "☑":  "⬜"}</span > */}
                    {data?.skuNo}
                  </option>
                )
              })}
            </select>
          </div>
          {/* SKU DESC */}
          <div className='w-[20%]'>
            <input
              type='text'
              required
              placeholder={SkuDescription}
              value={formData.skuDescription || ''}
              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none'
              disabled
            />
          </div>
          {/* UPc List */}
          <div className='w-auto'>
            <select
              name='uPC'
              class='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 '
              onChange={(e) => handleUPC(index, 'uPC', e.target.value)}
            >
              <option selected>UPC</option>

              {UPCList?.map((data) => {
                return (
                  <option value={JSON.stringify(data)} className=''>
                    {/* <span className='px-3'>{selectedSku?.find((item) => item === data?.skuNo) ? "☑":  "⬜"}</span > */}
                    {data?.upcno}
                  </option>
                )
              })}
            </select>
          </div>
          {/* UnitPrice Input*/}
          <div className='w-[10%]'>
            <input
              type='number'
              required
              placeholder={UnitPrice}
              disabled
              value={formData.unitPrice || ''}
              className='bg-gray-50 border w-full   border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block  p-2.5 outline-none'
              // onChange={(e) => handleChange(index, 'unitPrice', e.target.value)}
            />
          </div>
          {/* Quntity Input*/}
          <div className='w-[10%]'>
            <input
              type='number'
              required
              placeholder={Quantity}
              value={formData.quantity || ''}
              className='bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block p-2.5 outline-none'
              onChange={(e) => handleChange(index, 'quantity', e.target.value)}
            />
          </div>
          {/* Extended Price Input*/}
          <div className='w-[10%]'>
            <input
              required
              type='number'
              disabled
              placeholder={ExtendedPrice}
              className='bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block  p-2.5 outline-none'
              value={formData?.quantity * formData?.unitPrice || ''}
              onChange={(e) => console.log(e.target.value)}
            />
          </div>
          {/* DC Select Box */}

          <div className='w-auto'>
            <select
              id='dcValue'
              name='dcValue'
              class='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 '
              onChange={(e) => handleChange(index, 'dcValue', e.target.value)}
            >
              <option selected>DC</option>
              {dcList?.map((data) => {
                return <option value={data?.DC_NAME}>{data?.DC_NAME}</option>
              })}
            </select>
          </div>
          {/* Request Sheep Week */}
          <div className='w-auto text-center'  style={{ zIndex: 999 }}>
            <div className='flex flex-col'>
              <ReactDatePicker
                showWeekNumbers
                filterDate={(date) => date.getDay() === 1}
                selected={date}
                onChange={(date) => {
                  handleChange(index, 'shipweek', date)
                  setDate(date)
                }}
                // value={formData.dateInput || new Date()}
                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mt-2' // Add margin-top
                calendarStartDay={1}
                showYearDropdown
                minDate={new Date()}
              />
            </div>
          </div>
          <div className='my-2 w-[20%]'>
            <textarea
              type='text'
              required
              name='message'
              onChange={(e) => handleChange(index, 'message', e.target.value)}
              placeholder={Message}
              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none'
            />
          </div>
          <div className='flex w-[5%] gap-1'>
            {/* Remove Button */}
            {formDataList.length === index + 1 && (
              <button type='submit' className='bg-gray-200 rounded-full p-1'>
                <svg
                  className='w-4 h-4   text-gray-800'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 18 18'
                >
                  <path
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M9 1v16M1 9h16'
                  />
                </svg>
              </button>
            )}
            {/* Add Button */}
            {formDataList.length > 1 && (
              <span
                onClick={() => removeForm(index)}
                className='bg-gray-200 rounded-full p-1'
              >
                <svg
                  className='w-4 h-4   text-gray-800'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 18 2'
                >
                  <path
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M1 1h16'
                  />
                </svg>
              </span>
            )}
          </div>
        </form>
      ))}

      {/* Submit Button */}
    </div>
  )
}
