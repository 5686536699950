import React from 'react'
import LandingPage from '../components/LandingPage'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'

const ServerErrorPage = () => {
  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem('userData'))
  const { t } = useTranslation()
  const { ServerErrorMessage, Contact,Contactus, Signout, GoBack,ifthisshouldnthavehappened} = t('ServerErrorPage')

  const handleGoBack = () => {
    navigate(-1)
  }
  const handleSignout = () => {
    const role = Cookies.get('role')
    const tokenId = Cookies.get('idcs_id_token')
    localStorage?.removeItem('userData')
    Cookies.remove('idcs_user_assertion')
    Cookies.remove('idcs_id_token')
    Cookies.remove('role')
    window.location.href = `${process.env.REACT_APP_ANOTHER_API_URL}/logout?role=${role}&token-id=${tokenId}`
  }

  return (
    <LandingPage>
      <div className='error-500'>
        <p className='text-gray-500'>{ServerErrorMessage}</p>
        <p className='text-gray-500 py-2'>
          <a href={Contact} style={{ textDecoration: 'underline' }}>
            {Contactus}
          {ifthisshouldnthavehappened}</a>{' '}
          
        </p>
        <div className='return-buttons flex center justify-center'>
          <button
            onClick={handleGoBack}
            className='flex bg-blue-600 text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mx-4'
          >
            {GoBack}
          </button>
          <button
            onClick={handleSignout}
            className='flex bg-blue-600 text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mx-4'
          >
            {Signout}
          </button>
        </div>
      </div>
    </LandingPage>
  )
}

export default ServerErrorPage
