import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ValidateAPI } from '../../utils/Utils'
import { useNavigate } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import Loading from '../Loading'
import { CommonAPICall } from '../../CommonAPiContext/context'
import { useTranslation } from 'react-i18next'

const VendorCRage = ({ vendor, cpiOffice,vendorName }) => {
  const user = JSON.parse(localStorage?.getItem('userData'))
  const navigate = useNavigate()
  const [tableData, setTableData] = useState([])
  const [columns, setColumns] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [selectedBuyer, setSelectedBuyer] = useState('')
  const [selectedVendor, setSelectedVendor] = useState('')
  const [selectSku, setSelectSku] = useState('')
  const { addFilterData } = CommonAPICall()
  const {t} = useTranslation()
  const { CRAging} = t('vendorcrpage')
  const fetchTableData = () => {
    console.log('cpiOffice:', cpiOffice)
    console.log('vendor:', vendor)
    console.log('supplierId:', user?.supplierId)
    setLoading(true)
    const overseasURL = `${process.env.REACT_APP_API_DOMAIN}:8088/api/admin/get-cr-ageing-counts?vendorNumber=${vendor}&cpioffice=${cpiOffice}`
    const vendorURL = `${process.env.REACT_APP_API_DOMAIN}:8088/api/admin/get-cr-ageing-counts?vendorNumber=${user?.supplierId}`
    const URL =
      user?.userGroupName === 'OVERSEAS_ROLE' ? overseasURL : vendorURL
    console.log('Constructed URL:', URL)
    axios
      .get(URL, ValidateAPI())
      .then((response) => {
        console.log('API Response:', response.data)
        const data = response.data
        setTableData(data)

        if (data.length > 0) {
          // Extract column names dynamically from the first object
          const dynamicColumns = Object.keys(data[0])
          setColumns(dynamicColumns)
        }

        setLoading(false)
      })
      .catch((err) => {
        // if (error.response.status === 401 || error.response.status === 403) {
        //     Navigate('/login-error')
        //   }
        setError('Error fetching data')
        setLoading(false)
      })
  }

  // const handleCellClick = () => {
  //   navigate('/vendor/po_change_requests');
  // };
  // const handleHeaderClick = (col, row) => {
  //   const selectedMetric = col
  //   //getting 0-2 Days need to split number by 0 and 2
  //   const selectedValue = row?.includes('7')
  //     ? [7, '']
  //     : row?.split(' ')[0]?.split('-')
  //     addFilterData({
  //       hftUserId: user?.supplierId,
  //       tableName: 'VendorChangeReqTable',
  //       clFilter: []
  //     })
  //   if (user?.userGroupName === 'OVERSEAS_ROLE') {
  //     console.log('role', user?.userGroupName)
  //     // return

  //     addFilterData({
  //       hftUserId: user?.supplierId,
  //       // tableName: 'VendorChangeReqTable',
  //       clFilter: [
  //         {
  //           column: 'REQUEST_TYPE',
  //           values: [selectedMetric]
  //         },
      
  //         {
  //           column: 'SUPPLIER_RESPONSE',
  //           values: ['Awaiting Response']
  //         }
          
  //         // {
  //         //   column: 'crAge',
  //         //   values: [selectedValue[0], selectedValue[1]]
  //         // }
  //       ]
  //     })
  //     const overseasClFilter = [
  //       {
  //           id: 'REQUEST_TYPE',
  //           value: selectedMetric
  //       },
  //       {
  //         id: 'CR_AGE',
  //         value: [selectedValue[0], selectedValue[1]]
  //       },
  //       {
  //         id: 'SUPPLIER_RESPONSE',
  //         value: 'Awaiting Response'
  //       }
        
  //   ];
  //   // Navigate to the buyer page with the overseas-specific filter
  //   navigate(`/buyer/po_list`, { state: overseasClFilter });
  //   return;
  //   }
  //  setTimeout(() => {
  //   addFilterData({
  //     hftUserId: user?.supplierId,
  //     tableName: 'VendorChangeReqTable',
  //     clFilter: [
  //       {
  //         column: 'request_type',
  //         values: [selectedMetric]
  //       },
  //       {
  //         column: 'status',
  //         values: ['OPEN']
  //       },
  //       {
  //         column: 'supplier_response',
  //         values: ['Awaiting Response']
  //       }
        
  //       // {
  //       //   column: 'crAge',
  //       //   values: [selectedValue[0], selectedValue[1]]
  //       // }
  //     ]
  //   })
  //   const clFilter = [
  //     {
  //       id: 'request_type',
  //       value: selectedMetric
  //     },
  //     {
  //       id: 'crAge',
  //       value: [selectedValue[0], selectedValue[1]]
  //     },
  //     {
  //       id: 'status',
  //       value: 'OPEN'
  //     },
  //     {
  //       id: 'supplier_response',
  //         value: 'Awaiting Response'
  //     }
  //   ]
  //   navigate(`/vendor/po_change_requests`, { state: clFilter })
  //  }, 100);
  // }

  const handleHeaderClick = (col, row) => {
    const selectedMetric = col
    //getting 0-2 Days need to split number by 0 and 2
    const selectedValue = row?.includes('7')
      ? [7, '']
      : row?.split(' ')[0]?.split('-')
      addFilterData({
        hftUserId: user?.hftOracleId || user?.supplierId,
        tableName: 'VendorChangeReqTable',
        clFilter: []
      })
    if (user?.userGroupName === 'OVERSEAS_ROLE') {
      console.log('role', user?.userGroupName)
      addFilterData({
        hftUserId: user?.hftOracleId,
        tableName: 'NewPoList',
        clFilters: []
      })
      setTimeout(() => {
        console.log('Selected Metric:', selectedMetric);
    console.log('Selected Value:', selectedValue);
        addFilterData({
          hftUserId: user?.hftOracleId,
          tableName: 'NewPoList',
          buyerName:{},
          vendorName:vendor?{"id": vendor,"value": vendorName}:{},
          sku:{},
          clFilter: [
            {
              column: 'REQUEST_TYPE',
              values: [selectedMetric]
            },
           
            {
              column: 'SUPPLIER_RESPONSE',
              values: ['Awaiting Response']
            },
           
          ]
        })
        const clFilter = [
          {
            id: 'REQUEST_TYPE',
            value: selectedMetric
          },
          {
            id: 'CR_AGE',
            value: [selectedValue[0], selectedValue[1]]
          },
          {
            id: 'SUPPLIER_RESPONSE',
              value: 'Awaiting Response'
          }
        ]
        navigate(`/buyer/po_list`, { state: clFilter })
       }, 100);
       return
      
    }
   setTimeout(() => {
    addFilterData({
      hftUserId: user?.supplierId,
      tableName: 'VendorChangeReqTable',
      clFilter: [
        {
          column: 'request_type',
          values: [selectedMetric]
        },
        {
          column: 'status',
          values: ['OPEN']
        },
        {
          column: 'supplier_response',
          values: ['Awaiting Response']
        }
      ]
    })
    const clFilter = [
      {
        id: 'request_type',
        value: selectedMetric
      },
      {
        id: 'crAge',
        value: [selectedValue[0], selectedValue[1]]
      },
      {
        id: 'status',
        value: 'OPEN'
      },
      {
        id: 'supplier_response',
          value: 'Awaiting Response'
      }
    ]
    navigate(`/vendor/po_change_requests`, { state: clFilter })
   }, 100);
  }

  useEffect(() => {
    fetchTableData()
  }, [vendor])

  

  return (
    <div className="w-full overflow-x-auto flex justify-center items-center flex-col gap-4 shadow-lg rounded-md border border-gray-300 p-3 max-h-[360px]">
      <p className="text-md font-medium">{CRAging}</p>

      {loading ? (
        <Loading />
      ) : (
        
        <table className='table-auto border-collapse border border-gray-300 w-full rounded-lg '>
          
          <thead>
            <tr>
              {columns
               .filter((col) => col !== "DELAY")
              .map((col, index) => (
                <th
                  key={index}
                  className='border border-gray-300 p-2 font-medium'
                  style={{ minWidth: '150px', maxWidth: '200px' }}
                >
                  {col}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns
                 .filter((col) => col !== "DELAY")
                .map((col, colIndex) => {
                  let cellClass = 'border border-gray-300 p-2 text-center'

                  cellClass +=
                    row.DAYS === '3-6 days' && col === 'DAYS'
                      ? ' bg-yellow-500'
                      : row.DAYS === '>=7 days' && col === 'DAYS'
                        ? ' bg-red-500'
                        : ''

                  // cellClass +=
                  //   col !== 'DAYS'
                  //     ? user?.userGroupName !== 'OVERSEAS_ROLE'
                  //       ? ' text-blue-500 underline cursor-pointer'
                  //       : ' text-black'
                  //     : ''
                  if(col !== 'DAYS'){
                    cellClass += " text-blue-500 underline cursor-pointer"
                  }

                  const cellContent =
                    col !== 'DAYS' ? (
                      <span
                        onClick={() => handleHeaderClick(col, row.DAYS)}
                        className='cursor-pointer'
                      >
                        {row[col]}
                      </span>
                    ) : (
                      row[col]
                    )

                  return (
                    <td key={colIndex} className={cellClass}>
                      {cellContent}
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default VendorCRage
