import React from 'react';
import LandingPage from '../components/LandingPage';
import SignoutHandler from '../components/SignoutHandler';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SerVerIssue = () => {
  const {t} = useTranslation()
  const{Gotohomepage,
    OopsInternalServerError,
    UnfortunatelywerehavingtroubleloadingthepageyouarelookingforPleasecomebackinawhile} = t('serverissuepage')
  return (
    <LandingPage>
    <div className="flex flex-col items-center justify-center ">
        <div className=' rounded-full bg-gray-100 p-5'>
            <span className="text-red-400 text-4xl font-extrabold">500</span>
        </div>
          <div className="bg-white p-6 rounded-lg space-y-2"> 
          <h1 className='text-3xl font-pop text-sky-300'>{OopsInternalServerError}</h1>
          <p className='text-gray-500'>
         {UnfortunatelywerehavingtroubleloadingthepageyouarelookingforPleasecomebackinawhile}
          </p>
           
          </div>
          <Link to='/' className='bg-orange-400 p-2 rounded-md'>{Gotohomepage}</Link>
        </div>
   
    </LandingPage>
  );
};

export default SerVerIssue;

