import React, { useEffect, useState } from 'react'

const PopupModal = ({
  isOpen,
  message,
  onCancel,
  cancelText,
  onConfirm,
  confirmText
}) => {
  const [isDragging, setIsDragging] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [offset, setOffset] = useState({ x: 0, y: 0 })

  // Center the modal on mount
  useEffect(() => {
    const centerModal = () => {
      const modalWidth = document.querySelector('.modal-content').offsetWidth
      const modalHeight = document.querySelector('.modal-content').offsetHeight

      setPosition({
        x: (window.innerWidth - modalWidth) / 2,
        y: (window.innerHeight - modalHeight) / 2
      })
    }

    if (isOpen) {
      centerModal()
    }
  }, [isOpen])

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isDragging) {
        const maxX = window.innerWidth - 420 // Adjust based on the width of your modal
        const maxY = window.innerHeight - 160 // Adjust based on the height of your modal

        let newX = e.clientX - offset.x
        let newY = e.clientY - offset.y

        newX = Math.max(0, Math.min(newX, maxX))
        newY = Math.max(0, Math.min(newY, maxY))

        setPosition({ x: newX, y: newY })
      }
    }

    const handleMouseUp = () => {
      setIsDragging(false)
    }

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDragging, offset])

  const handleMouseDown = (e) => {
    setIsDragging(true)
    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y
    })
  }

  if (!isOpen) return null
  return (
    <div className='p-3 inset-0 fixed z-50 bg-black bg-opacity-50 w-full min-h-screen'>
  <div
      className='modal-overlay fixed text-sm z-30 w-1/3 max-w-xl min-w-min justify-center items-center bg-white shadow-lg shadow-black'
      style={{ top: position.y, left: position.x }}
    >
      <div
        className='modal-container flex-col w-full'
        onMouseDown={handleMouseDown}
      >
        <div className='modal-header flex float-right p-1'>
          <button
            className='modal-btnClose text-bold text-neutral-500'
            onClick={onCancel}
          >
            &#10006;
          </button>
        </div>
        <div className='modal-content flex flex-col w-full py-2 justify-center'>
          <p className='modal-message font-bold self-center text-black'>{message}</p>
          <div className='modal-buttons flex p-4 pb-4 align-middle w-full justify-center'>
           { cancelText &&<button
              onClick={onCancel}
              className='modal-btnCancel w-40 m-2 p-3 border border-solid text-white bg-gray-500'
            >
              {cancelText}
            </button>}
            <button
              onClick={onConfirm}
              className='modal-btnConfirm w-40 m-2 p-3 border border-solid text-white bg-green-500'
            >
              {confirmText}
            </button>
          </div>
        </div>
      </div>
    </div>
    </div>
  
  )
}

export default PopupModal
