import React, { useState } from 'react'
import UserProfile from './UserProfile'
import logo1 from '../image/logo_head1.svg'
import { useTranslation } from 'react-i18next'
const Header = ({ showSidebar }) => {
  const [showLegend, setShowLegend] = useState(false)
  const{t} = useTranslation()
  const {Poweredby,ActiveCR,CRHistory,PastDue} = t("Headerpage")

  return (
    <nav className='min-w-full flex px-4 justify-between items-center shadow-xl'>
      <div className='flex gap-2 items-center justify-center'>
        <svg
          className='w-6 h-8 text-gray-800 cursor-pointer'
          aria-hidden='true'
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          onClick={() => showSidebar((prev) => !prev)}
        >
          <path
            stroke='currentColor'
            strokeLinecap='round'
            strokeWidth='3'
            d='M5 7h14M5 12h14M5 17h14'
          />
        </svg>
        <img
          src={logo1}
          alt=''
          height='100%'
          width='100%'
          className='w-[140px] h-[50px]'
        />
        <span className='text-gray-300 text-lg'>|</span>
        <span className='text-gray-600 text-md font-light'>{Poweredby} :</span>
        <img
          src='https://images.harborfreight.com/media/logos/harborfreight-logo.svg'
          alt=''
          // height='100%'
          // width='100%'
          className='w-[140px] h-[20px]'
        />
      </div>
      <div className='text-sm flex items-center gap-3 justify-center'>
        {(window?.location?.pathname?.includes('open_pos') ||
          window?.location?.pathname?.includes('po_list')) && (
          <div className='relative flex flex-col gap-3 justify-center items-center'>
            <button
              onMouseOver={() => setShowLegend(true)}
              onMouseOut={() => setShowLegend(false)}
              className='flex justify-center items-center'
            >
              <svg
                className='w-6 h-6 text-gray-800'
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 20 20'
              >
                <path
                  stroke='currentColor'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M8 9h2v5m-2 0h4M9.408 5.5h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
                />
              </svg>
            </button>
            {showLegend && (
              <div className='border border-gray-300 p-2 flex flex-col gap-2 divide-y-1 divide-gray-300 rounded-md absolute w-[130px] top-5 right-3 z-10 text-xs bg-[#fff]'>
                <p className=' flex items-center justify-start gap-2'>
                  <span>
                    <svg
                      className='w-5 h-5 text-[#ffae42] cursor-pointer'
                      aria-hidden='true'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='currentColor'
                      viewBox='0 0 20 20'
                    >
                      <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z' />
                    </svg>
                  </span>{' '}
                  -<span>{ActiveCR}</span>
                </p>
                <p className=' flex items-center justify-start gap-2'>
                  <span>
                    <svg
                      className='w-4 h-4 text-gray-900 cursor-pointer'
                      aria-hidden='true'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 20 20'
                    >
                      <path
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M18 5h1v12a2 2 0 0 1-2 2m0 0a2 2 0 0 1-2-2V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v15a2 2 0 0 0 2 2h14ZM10 4h2m-2 3h2m-8 3h8m-8 3h8m-8 3h8M4 4h3v3H4V4Z'
                      />
                    </svg>
                  </span>{' '}
                  -<span>{CRHistory}</span>
                </p>
                <p className=' flex items-center justify-start gap-2'>
                  <span>
                    <svg
                      className='w-4 h-4 text-red-500'
                      aria-hidden='true'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='currentColor'
                      viewBox='0 0 20 20'
                    >
                      <path d='M0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm14-7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-5-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-5-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4Z' />
                    </svg>
                  </span>{' '}
                  -<span>{PastDue}</span>
                </p>
              </div>
            )}
          </div>
        )}
        <UserProfile />
      </div>
    </nav>
  )
}

export default Header
