import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { formatDateFit } from '../buyer/BuyerRequestDetails'
import {
  DatefilterFn,
  ValidateAPI,
  getStartDate,
  handleAcceptExpediteDeferRequests,
  handlePODetailsClick,
  handleRejectExpediteDeferDivertRequest,
  reasonCodesEN,
  reasonCodesMN
} from '../../utils/Utils'
import ConfirmTable from '../../components/ConfirmTable'
import DatePicker from 'react-datepicker'
import { getWeek } from '../buyer/NewPoList'
import { EditedRowState } from '../../LineContext/Context'
import Loading from '../../components/Loading'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import PopupModal from '../../components/PopupModal'
import PopupBanner from '../../components/PopupBanner'
import ReasonCodeList, { ReasonCodeView } from '../../components/ReasonCodeList'
import PopUpPastDue from '../../components/PopUpPastDue'
import { checkvalidpastDue } from '../../components/vendor/ExpediteActionComp'

export const LineComp = ({
  row,
  requestType,
  selectTableRow,
  rowSelection,
  updateRowSelection
}) => {
  const [showInput, setShowInput] = useState(false)
  const [value, setValue] = useState('')
  const inputRef = useRef(null)
  const [date, setDate] = useState(new Date())
  const [showValue, setShowValue] = useState(false)
  
  const [currentRows, setCurrentRows] = useState([])
  // const [reason_codes, setReasonCode] = useState([])
  const {
    state: { editArray },
    dispatch
  } = EditedRowState()

  const handleUpdate = (date) => {
    console.log('shipweek value: ', date)
    console.log('row data: ', row)
    const updatedRow = {
      ...row?.original,
      requested_ship_week: date,
      row_id: row?.id
    }
    console.log('row selection before: ', updatedRow)
    if (
      editArray?.some(
        (c) => c?.id_open_request === row?.original?.id_open_request
      )
    ) {
      dispatch({
        type: 'UPDATE_SW_VALUE',
        payload: {
          id: row?.original?.id_open_request,
          sw: date
        }
      })
    } else {
      dispatch({
        type: 'ADD_TO_CR_LIST',
        payload: updatedRow
      })
      const updatedRowId = row.id
      updateRowSelection((prevSelection) => ({
        ...prevSelection,
        [updatedRowId]: true
      }))
    }
    setShowValue(true)
  }

  const resetValue = () => {
    setShowInput(false)
    setValue(row?.original?.CURRENT_SW)
    setShowValue(false)
    dispatch({
      type: 'REMOVE_CR_FROM_LIST',
      payload: {
        id: row?.original?.id_open_request
      }
    })
    updateRowSelection((prevSelection) => ({
      ...prevSelection,
      [row.id]: false
    }))
  }
  const handleChangeDate = (date) => {
    setDate(date)
    setValue(getWeek(date))
    handleUpdate(getWeek(date))
    setShowInput(false)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowInput(false)
      }
    }
    document.body.addEventListener('click', handleClickOutside)
    return () => {
      document.body.removeEventListener('click', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    setValue(row?.original?.CURRENT_SW)
  }, [row?.id])

  useEffect(() => {
    console.log('rowSelection from lineComp:', rowSelection)
    setCurrentRows(rowSelection)
  }, [rowSelection])
  return (
    <div className='w-full flex justify-center items-center p-1'>
      {showInput ? (
        <div>
          <DatePicker
            showWeekNumbers
            filterDate={(date) => date.getDay() === 1}
            selected={date}
            onChange={(date) => handleChangeDate(date)}
            className='p-1 z-20 w-full'
            showIcon
            scrollableYearDropdown
            popperClassName='react-datepicker__week-number'
            formatWeekDay={(day) => day.slice(0, 3)}
            calendarStartDay={1}
            showYearDropdown
            maxDate={
              requestType === 'expedite'
                ? getStartDate(row?.original?.current_ship_week)
                : getStartDate(row?.original?.requested_ship_week)
            }
            minDate={
              requestType === 'expedite'
                ? getStartDate(row?.original?.requested_ship_week)
                : getStartDate(row?.original?.current_ship_week)
            }
            onBlur={() => {
              setShowInput(false)
            }}
            autoFocus
          />
        </div>
      ) : (
        <div className='w-full flex flex-col items-center justify-center gap-1'>
          <p className='cursor-pointer' onClick={() => setShowInput(true)}>
            {showValue ? value : row?.original?.current_ship_week}
          </p>
          {editArray?.some(
            (c) => c?.id_open_request === row?.original?.id_open_request
          ) && (
              <div className='w-full justify-end items-center flex'>
                <button onClick={() => resetValue()}>
                  <svg
                    className='w-6 h-6 text-gray-800 dark:text-white'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='currentColor'
                    viewBox='0 0 24 24'
                  >
                    <path
                      fillRule='evenodd'
                      d='M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm7.7-3.7a1 1 0 0 0-1.4 1.4l2.3 2.3-2.3 2.3a1 1 0 1 0 1.4 1.4l2.3-2.3 2.3 2.3a1 1 0 0 0 1.4-1.4L13.4 12l2.3-2.3a1 1 0 0 0-1.4-1.4L12 10.6 9.7 8.3Z'
                      clipRule='evenodd'
                    />
                  </svg>
                </button>
              </div>
            )}
        </div>
      )}
    </div>
  )
}

const Expedite = () => {
  const [requestData, setRequestData] = useState([])
  const user = JSON.parse(localStorage.getItem('userData'))
  const [loading, setLoading] = useState(false)
  const [selectedRowArray, setSelectedRowArray] = useState([])
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [reason_codes, setReasonCode] = useState(reasonCodesEN ?? [])
  const [reasonCodeValue, setreasonCodeValue] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [rowSelection, setRowSelection] = useState({})
  const [showExpediteAcceptModal, setShowExpediteAcceptModal] = useState(false)
  const [showChangeRequestBanner, setShowChangeRequestBanner] = useState(false)
  const [showRejectRequestBanner, setShowRejectRequestBanner] = useState(false)
  const [editCrIdResponse, setEditCrIdResponse] = useState('')
  const [editPoRow, setEditPORow] = useState('')
  const [poRow, setPoRow] = useState('')
  const [crIdResponse, setCrIdResponse] = useState('')
  const [validSw, setValidSw] = useState(false)
  const [showpastDueModel, setShowPastDueModel] = useState(false)
  const { t } = useTranslation()
  const tableId = 'ExpediteTable' // Define tableId here
  const {
    ExpediteRequests,

    Home,
    Submitting_load,
    Pleaseselectreasoncodeforrejectingtheorder,
    Selectareason,
    PleaseselectaPO,
    ToProposeNewSWClickOnCR,
    NOTE,
    Reject,
    Cancel
  } = t('ExpditePage')
  const {
    state: { editArray },
    dispatch
  } = EditedRowState()

  const navigate = useNavigate()

  // Access translated strings outside useMemo
  const headers = {
    CR: t('ExpditePage.CR'),
    PONUMBER: t('ExpditePage.PONUMBER'),
    SKU: t('ExpditePage.SKU'),
    STATUS: t('ExpditePage.STATUS'),
    REQUESTTYPE: t('ExpditePage.REQUESTTYPE'),
    REQUESTEDBY: t('ExpditePage.REQUESTEDBY'),
    CREATEDATE: t('ExpditePage.CREATEDATE'),
    UPDATEDATE: t('ExpditePage.UPDATEDATE'),
    CRSTATUS: t('ExpditePage.CRSTATUS'),
    VENDORRESPONSE: t('ExpditePage.VENDORRESPONSE'),
    // REQSW: t('POList.REQSW'),
    CHANGEDSW: t('POList.CHANGEDSW'),
    ORGSW: t('POList.ORGSW'),
    CURRSW: t('POList.CURRENTSW'),
    ONTIMETARGET: t('POList.ONTIMETARGET'),
    DELTA: t('ConfirmPOpage.DELTA')
  }
  // Access translated strings outside useMemo
  const columns = useMemo(
    () => [
      {
        header: headers.CR,
        accessorKey: 'id_open_request',
        cell: ({ row }) => (
          <p
            className='underline cursor-pointer text-blue-500'
            onClick={() =>
              navigate(
                `/vendor/po_change_requests/request_details/${row?.original.id_open_request}`,
                { state: row?.original }
              )
            }
          >
            {row?.original?.id_open_request}
          </p>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.PONUMBER,
        accessorKey: 'po_number',
        cell: (info) => {
          return (
            <p
              className='underline cursor-pointer text-blue-500'
              onClick={() => handlePODetailsClick('vendor', info?.getValue())}
            >
              {info?.getValue()}
            </p>
          )
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.SKU,
        accessorKey: 'sku',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.CHANGEDSW,
        accessorKey: 'requested_ship_week',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.ORGSW,
        accessorKey: 'original_ship_week',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.CURRSW,
        accessorKey: 'current_ship_week',
        // cell: ({ row }) => (
        //   <LineComp
        //     row={row}
        //     requestType={'expedite'}
        //     selectTableRow={handleRowSelection}
        //     rowSelection={selectedRowArray}
        //     updateRowSelection={setRowSelection}
        //   />
        // ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.ONTIMETARGET,
        accessorKey: 'on_time_target',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.DELTA,
        accessorKey: 'ship_week_delay',
        filterFn: (row, id, value) => {
          const cellValue = row.getValue(id)
          // console.log("value",value)
          // console.log("cellvalue",cellValue)

          // // Check if cellValue is null or undefined before calling toString()
          if (cellValue == null) {
            return false // or any other logic you want to apply when the value is null
          }
          let words = value.split(/[\s,]+/).map((word) => word.toLowerCase()) // Split by comma and spaces
          return words.includes(cellValue?.toLowerCase())
        }
      },
      {
        header: headers.VENDORRESPONSE,
        accessorKey: 'supplier_response',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.CRSTATUS,
        accessorKey: 'status',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.REQUESTEDBY,
        accessorKey: 'created_by',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.CREATEDATE,
        accessorKey: 'created_at',
        cell: (info) =>
          info?.getValue() !== null ? formatDateFit(info?.getValue()) : '',
        filterFn: DatefilterFn
      }
      // {
      //   header: headers.UPDATEDATE,
      //   accessorKey: 'updated_at',
      //   cell: DatefilterFn
      // }
    ],
    [headers]
  )

  const handleRowSelection = (rowId) => {
    // rowSelectionFunction(rowId)
    // Perform actions you want in the parent component
  }

  const closeExpediteAcceptModal = () => {
    setShowExpediteAcceptModal(false)
  }

  const checkpastDue = ()=>{
    return selectedRowArray?.some((c) => checkvalidpastDue(c?.requested_ship_week))
  }
  const openExpediteAcceptModal = () => {
    if (checkpastDue())
      setShowPastDueModel(true)
    else
    setShowExpediteAcceptModal(true)
  }

  const closePopUpModal = () => {
    // setPoRow(false)
    setShowRejectRequestBanner(false)
    setShowChangeRequestBanner(false)
  }

  const handleAcceptRequest = () => {
    setSubmitting(true)
    setShowRejectModal(true)
    handleAcceptExpediteDeferRequests(
      setRequestData,
      selectedRowArray,
      setSubmitting,
      setShowRejectModal,
      (success, data) => {
        if (success) {
          if (data) {
            const crIdResponse = data.map((item) => item.id_open_request)
            const poRow = data.map((item) => item.po_number)
            setCrIdResponse(crIdResponse)
            setPoRow(poRow)
          }
          setShowChangeRequestBanner(true)
          fetchAllRequests()
        }
      }
    )
    closeExpediteAcceptModal()
  }

  const fetchAllRequests = () => {
    setLoading(true)
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-supplier-cr-type-data?vendorNumber=${user?.supplierId}&crType=EXPEDITE`,
         ValidateAPI()
      )
      .then((response) => {
        console.log('response from FCR: ', response?.data)
        if (response?.data?.[0]?.Message) return setRequestData([])
        setRequestData(response?.data)
        setLoading(false)
      })
      .catch((error) => {
        console.log(`error while FRC: `, error)
        setLoading(false)
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
  }

  useEffect(() => {
    console.log('context Array: ', editArray)
    setSelectedRowArray([...editArray])
  }, [editArray])

  useEffect(() => {
    console.log('selectedRow Array: ', selectedRowArray)
  }, [selectedRowArray])

  useEffect(() => {
    const handleStorage = () => {
      if (window.localStorage?.getItem('language') === 'en') {
        setReasonCode(reasonCodesEN)
      } else {
        setReasonCode(reasonCodesMN)
      }
    }

    window.addEventListener('storage', handleStorage())
    return () => window.removeEventListener('storage', handleStorage())
  }, [window.localStorage?.getItem('language')])

  useEffect(() => {
    fetchAllRequests()

    return () => {
      dispatch({
        type: 'CLEAR_LIST'
      })
    }
  }, [])

  useEffect(() => {
    const handleClickOutside = () => {
      setShowChangeRequestBanner(false)
      setShowRejectRequestBanner(false)
    }
    if (showChangeRequestBanner || showRejectRequestBanner) {
      window.addEventListener('click', handleClickOutside)
    }
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [showChangeRequestBanner, showRejectRequestBanner])
  useEffect(() => {
    if (selectedRowArray?.length > 0) {
      setValidSw(false); // Hide the error message as soon as an order is selected
    }
  }, [selectedRowArray]);

  return (
    <div className='w-full flex flex-col gap-6 justify-start items-start p-4'>
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl'>{ExpediteRequests}</p>
        </div>
        {/* <UserProfile /> */}
      </div>
      <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/vendor')}
          >
            {Home}
          </span>
          <span>{'>'}</span>
        </p>
        <p className='flex gap-1 items-center justify-center'>
          <span>{ExpediteRequests}</span>
        </p>
      </div>

      {/* button handle */}
      <div>
        <div className='flex w-full justify-start items-center font-medium text-xs gap-3'>
          <button
            className='px-3 rounded-md bg-green-500 text-white h-[32px]'
            onClick={() => {
              if (selectedRowArray?.length === 0) {
                setValidSw(true)
              } else {
                openExpediteAcceptModal()
              }
            }}
          // disabled={selectedRowArray?.length === 0}
          >
            <span>{t('ExpditePage.Accept')}</span>
          </button>

          <PopupModal
            isOpen={showExpediteAcceptModal}
            message={i18next.t('PopUpMessage.Doyouwanttoaccepttheserequests')}
            onCancel={closeExpediteAcceptModal}
            cancelText={i18next.t('AcceptModal.Cancel')}
            onConfirm={handleAcceptRequest}
            confirmText={i18next.t('AcceptModal.Confirm')}
          />
          <PopUpPastDue
            isOpen={showpastDueModel}
            message={`Selected CR Is In Past Due, Can't Accept, but Can Reject`}
            onCancel={() => setShowPastDueModel(false)}
            cancelText={i18next.t('AcceptModal.Cancel')}
            onConfirm={()=>{setShowRejectModal(true);setShowPastDueModel(false)}}
            confirmText={'Reject'}
            type={'reject'}
          />

          <button
            className='px-3 rounded-md bg-red-500 text-white h-[32px]'
            onClick={() => {
              if (selectedRowArray?.length === 0) {
                setValidSw(true) // Show the error message
              } else {
                setShowRejectModal(true)
              }
            }}
          // disabled={selectedRowArray?.length === 0}
          >
            {t('ExpditePage.Reject')}
          </button>
        </div>
        {validSw && (
          <p className='text-red-500 text-xs mt-2'>{PleaseselectaPO}</p>
        )}
      </div>
      <p className="text-yellow-600 text-sm italic my">
        <span className="font-bold text-black">{NOTE} : </span> {ToProposeNewSWClickOnCR}
      </p>

      {loading ? (
        <div className='w-full min-h-[300px] flex justify-center items-center'>
          <Loading />
        </div>
      ) : (
        <div className='w-full overflow-x-auto'>
          <ConfirmTable
            tableId={tableId}
            tableData={requestData}
            columns={columns}
            selection={setSelectedRowArray}
            updateRowSelectionProp={setRowSelection}
            rowSelectionProp={rowSelection}
          />
        </div>
      )}
      <dialog
        open={showRejectModal}
        className='p-3 inset-0 fixed z-50 bg-black bg-opacity-50 w-full min-h-screen'
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center'>
          {submitting ? (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <Loading type={Submitting_load} />
            </div>
          ) : (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <div className='w-full flex justify-end items-center'>
                <button
                  className='border-2 border-gray-300 p-2 rounded-md'
                  onClick={() => {
                    setShowRejectModal(false)
                    setreasonCodeValue('')
                  }}
                >
                  ❌
                </button>
              </div>
              <div className='w-full flex justify-center items-center mt-2 text-center'>
                <p className='text-sm font-medium'>
                  {Pleaseselectreasoncodeforrejectingtheorder}
                </p>
              </div>
              {/* This is to show reasonCodeValue once user selected from list  */}
              <ReasonCodeView
                reasonCodeValue={reasonCodeValue}
                setreasonCodeValue={setreasonCodeValue}
              />
              {/* View End */}

              {/*  ReasonCode List Component to view all list and select*/}
              <ReasonCodeList
                reasonCodeValue={reasonCodeValue}
                setreasonCodeValue={setreasonCodeValue}
              />
              {/* End Component */}
              <div className='w-full flex justify-center items-center gap-4 mt-4'>
                <button
                  className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-red-500 outline-none'
                  onClick={() =>
                    handleRejectExpediteDeferDivertRequest(
                      setRequestData,
                      selectedRowArray,
                      reasonCodeValue,
                      setShowRejectModal,
                      setreasonCodeValue,
                      setSubmitting,
                      (success, data) => {
                        if (success) {
                          if (data) {
                            const editCrIdResponse = data.map(
                              (item) => item.id_open_request
                            )
                            const editPoRow = data.map((item) => item.po_number)
                            console.log('editCrIdResponse:', editCrIdResponse)
                            console.log('editpo response', editPoRow)
                            setEditPORow(editPoRow)
                            setEditCrIdResponse(editCrIdResponse)
                          }
                          setShowRejectRequestBanner(true)
                          fetchAllRequests()
                        }
                      }
                    )
                  }
                >
                  {Reject}
                </button>
                <button
                  className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-gray-500 outline-none'
                  onClick={() => {
                    setShowRejectModal(false)
                    setreasonCodeValue('')
                  }}
                >
                  {Cancel}
                </button>
              </div>
            </div>
          )}
        </div>
      </dialog>
      <PopupBanner
        isOpen={showChangeRequestBanner}
        message={'Expedite Order Submitted Successfully'}
        onCancel={closePopUpModal}
        text={poRow}
        text1={crIdResponse}
      />
      <PopupBanner
        isOpen={showRejectRequestBanner}
        message={'Expedite Orders Rejected  Successfully'}
        onCancel={closePopUpModal}
        text={editPoRow}
        text1={editCrIdResponse}
      />
    </div>
  )
}

export default Expedite
